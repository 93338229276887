import React, { useEffect, useState } from 'react';
import history from '../../../../utils/history';
import images from '../../../../assets/icons';
//import { stringToCurrency } from '../../../../utils/currency';
// import Button from '../../../../components/common/Button';
import Button from '@material-ui/core/Button';
//import SidebarAlert from '../../SidebarAlert';
import { Grid, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PaymentFeedback from '../../../paymentFeedback';
import PaymentFailed from '../../../paymentFailed';
import PaymentSuccess from '../../../paymentSuccess';
import SidebarAlert from '../../SidebarAlert';
import { makeStyles } from '@material-ui/core/styles';
import { mergeAndFormatAmount } from '../../../../utils/currency';

const OrangeButton = withStyles({
  root: {
    color: '#fff',
    backgroundColor: '#ff7330',
    border: '1px solid',
    borderColor: '#ff7330',
    borderRadius: 4,
    fontWeight: 'normal',
    padding: '8px 32px',
    marginTop: '32px',

    '&:hover': {
      backgroundColor: '#ff7330',
      borderColor: '#ff7330',
      boxShadow: 'none',
      color: '#fff',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#ff7330',
      borderColor: '#ff7330',
      color: '#fff',
    },
    '&:focus': {},
  },
})(Button);

const Card = (props) => {
  let {
    type,
    cardNo,
    accountNo,
    code,
    amount,
    billNo,
    firstName,
    surname,
    desc,
    activeVariant,
    transactionData,
  } = props;

  const isBollettino = activeVariant === 'bollettini';
  const data = {
    type: '674 – Premarcato non fatturatore',
  };
  /*   const type = '674 – Premarcato non fatturatore';
  const cardNo = '34567654356789765';
  const code = '28928763782983767829';
  const amount = '300.00';
  const billNo = 'Bolletta numero 5678';
  const firstName = 'Serena';
  const surname = 'Quaglia'; */
  return (
    <div className="bollettino-page__step-four__card scroll-element">
      <div className="bollettino-page__step-four__card__icon">
        <img
          src={
            activeVariant === 'mav-rav'
              ? images.mavRav
              : activeVariant === 'rata'
              ? images.rate
              : images.bollettini
          }
          alt={
            activeVariant === 'mav-rav'
              ? type === 'mav'
                ? 'MAV'
                : 'RAV'
              : activeVariant === 'rata'
              ? 'Rata'
              : 'bollettini'
          }
        />
      </div>
      <h2>Riepilogo bollettino</h2>
      <div className="bollettino-page__step-four__card__content">
        <div className="bollettino-page__step-four__card__item">
          <div className="bollettino-page__step-four__card__label">
            Tipologia di{' '}
            {activeVariant === 'bollettini' ? 'bollettino' : 'pagamento'}
          </div>
          <div className="bollettino-page__step-four__card__value">{type}</div>
        </div>
        <div className="bollettino-page__step-four__card__item">
          <div className="bollettino-page__step-four__card__label">
            Eseguito da
          </div>
          <div className="bollettino-page__step-four__card__value">{`${firstName} ${surname}`}</div>
        </div>
        {accountNo && (
          <div className="bollettino-page__step-four__card__item">
            <div className="bollettino-page__step-four__card__label">
              Numero C/C
            </div>
            <div className="bollettino-page__step-four__card__value">
              {accountNo}
            </div>
          </div>
        )}
        {code && (
          <div className="bollettino-page__step-four__card__item">
            <div className="bollettino-page__step-four__card__label">
              {activeVariant === 'bollettini'
                ? 'Codice bollettino'
                : `Codice ${type}`}
            </div>
            <div className="bollettino-page__step-four__card__value">
              {code}
            </div>
          </div>
        )}
        {desc && (
          <div className="bollettino-page__step-four__card__item">
            <div className="bollettino-page__step-four__card__label">
              Causale
            </div>
            <div className="bollettino-page__step-four__card__value">
              {desc}
            </div>
          </div>
        )}
      </div>
      {/* <div className="bollettino-page__step-four__card__row">
        <div className="bollettino-page__step-four__card__item">
          <div className="bollettino-page__step-four__card__label">
            Tipologia di{' '}
            {activeVariant === 'bollettini' ? 'bollettino' : 'pagamento'}
          </div>
          <div className="bollettino-page__step-four__card__value">{type}</div>
        </div>
        <div className="bollettino-page__step-four__card__item">
          <div className="bollettino-page__step-four__card__label">
            Eseguito da
          </div>
          <div className="bollettino-page__step-four__card__value">{`${firstName} ${surname}`}</div>
        </div>
      </div>
      <div className="bollettino-page__step-four__card__row">
        <div className="bollettino-page__step-four__card__item">
          <div className="bollettino-page__step-four__card__label">
            Numero C/C
          </div>
          <div className="bollettino-page__step-four__card__value">
            {accountNo}
          </div>
        </div>
        {code && (
          <div className="bollettino-page__step-four__card__item">
            <div className="bollettino-page__step-four__card__label">
              {activeVariant === 'bollettini'
                ? 'Codice bollettino'
                : `Codice ${type}`}
            </div>
            <div className="bollettino-page__step-four__card__value">
              {code}
            </div>
          </div>
        )}
      </div>
      {desc && (
        <div className="bollettino-page__step-four__card__row">
          <div className="bollettino-page__step-four__card__item">
            <div className="bollettino-page__step-four__card__label">
              Causale
            </div>
            <div className="bollettino-page__step-four__card__value">
              {desc}
            </div>
          </div>
        </div>
      )} */}
      <div className="bollettino-page__step-four__card__item total">
        <div className="bollettino-page__step-four__card__label">Importo</div>
        <div className="bollettino-page__step-four__card__value">{`${amount}`}</div>
      </div>
    </div>
  );
};

const StepFour = ({
  data: {
    type,
    cardNo,
    code,
    billNo,
    firstName,
    surname,
    accountNo,
    desc,
    email,
  } = {},
  activeVariant,
  setDataForRedirectionAfterLogin,
  user,
  transactionData,
  goToPayment,
}) => {
  const [isFail, setIsFail] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [amount, setAmount] = useState(0);
  const [receiptLink, setReceiptLink] = useState(null);
  const [isUser, setIsUser] = useState(false);
  useEffect(() => {
    if (user && user._id) {
      setIsUser(true);
    } else {
      setIsUser(false);
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (transactionData) {
  //     if (transactionData && transactionData.status === 'fail') {
  //       setIsFail(true);
  //       setIsSuccess(false);
  //     } else {
  //       setIsFail(false);
  //       setIsSuccess(true);
  //     }
  //     console.log(transactionData);
  //   }
  // }, [transactionData]);

  useEffect(() => {
    if (transactionData) {
      setIsSuccess(false);
      setIsFail(false);
      setIsPending(false);
      console.log('transactionData', transactionData);
      if (transactionData.receipt) {
        setReceiptLink(transactionData.receipt);
      }
      if (transactionData && transactionData.status === 'fail') {
        setIsFail(true);
      } else if (transactionData && transactionData.status === 'pending') {
        setIsPending(true);
      } else {
        setIsSuccess(true);
      }
      let AmountPartial = (
        (transactionData.amount +
          transactionData.stripeCommissionAmount +
          transactionData.condexoCommissionAmount) /
        100
      )
        .toString()
        .split('.');
      setAmount(mergeAndFormatAmount(AmountPartial[0], AmountPartial[1]));
      console.log('amount', amount, transactionData);
    }
  }, [transactionData]);

  return (
    <>
      {isSuccess && (
        <div>
          <Grid container justifyContent="center" spacing={2}>
            <Hidden mdDown>
              <Grid item style={{ width: '14%' }}>
                {' '}
              </Grid>
            </Hidden>
            <Grid item className="cardBollettino4step">
              <Card
                activeVariant={activeVariant}
                type={type ?? '674 – Premarcato non fatturatore'}
                cardNo={cardNo ?? '34567654356789765'}
                code={code ?? '28928763782983767829'}
                amount={amount ?? '300'}
                billNo={billNo ?? 'Bolletta numero 5678'}
                firstName={firstName}
                surname={surname}
                accountNo={accountNo}
                desc={desc}
              />
            </Grid>
            {receiptLink && (
              <Grid item>
                <SidebarAlert receiptLink={receiptLink} />
              </Grid>
            )}
          </Grid>
        </div>
      )}

      {isFail && <PaymentFailed isUser={isUser} />}
      {isPending && (
        <PaymentFeedback
          data={transactionData}
          goToPayment={goToPayment}
          isUser={isUser}
        />
      )}
    </>
  );
};

export default StepFour;
