import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Radio,
  Checkbox,
  Box,
  InputAdornment,
  Button,
} from '@material-ui/core';
import clsx from 'classnames';
import SelectWithSearch from '../../../../components/common/form/SelectWithSearch';
import { icons } from '../../utils';
import { Tooltip } from '../../../../components/common/Tooltip';
import { IMAGE_UPLOADS_URL } from '../../../../config';
import './RechargeScreen.scss';
import { makeStyles } from '@material-ui/core/styles';
import { mobileTopup } from '../../../../redux/sagas/EpaySaga';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 12,
    color: '#224670',
    lineHeight: '42px',
  },
  link: {
    fontSize: 12,
    color: '#4a90e2',
  },
}));

const TOOLTIP_TITLE =
  'Per procedere e necessario accettare le condiziani generali';

const RechargeScreen = (props) => {
  const [errors, setErrors] = useState({
    number: null,
    confirmNumber: null,
    optionalEmail: null,
    amount: null,
  });
  console.log('RechargeScreen', props);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // props.countryCodes

  useEffect(() => {
    // if (props.reserveTransactionId) handleStepForward();
  }, [props.reserveTransactionId]);

  const handleValidation = () => {
    let isValid = true;

    if (!props.rechargeForm.number) {
      setError('number', 'Numero di telefono richiesto');
      isValid = false;
    } else setError('number', null);

    if (!props.rechargeForm.confirmNumber) {
      setError('confirmNumber', 'Numero di telefono richiesto');
      isValid = false;
    } else if (props.rechargeForm.number !== props.rechargeForm.confirmNumber) {
      setError('confirmNumber', 'I due numeri non corrispondono.');
      isValid = false;
    } else setError('confirmNumber', null);

    if (props.isGuest && !props.rechargeForm.optionalEmail) {
      setError('optionalEmail', 'Inserire un indirizzo email valido.');
      isValid = false;
    } else if (
      props.rechargeForm.optionalEmail &&
      !validateEmail(props.rechargeForm.optionalEmail)
    ) {
      setError('optionalEmail', 'Inserire un indirizzo email valido.');
      isValid = false;
    } else setError('optionalEmail', null);

    if (!props.rechargeForm.amount.value) {
      setError('amount', 'Seleziona un importo.');
      isValid = false;
    } else {
      setError('amount', null);
    }

    if (!props.rechargeForm.privacy) {
      isValid = false;
    }

    return isValid;
  };

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const setError = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }));
  };

  const handleInputChange = ({ target }) => {
    props.changeRechargeForm(target.name, target.value);
  };

  const handleSelectChange = (value) => {
    props.changeRechargeForm('countryCode', value);
  };

  const handleStepBack = () => {
    props.changeStep(0);
  };

  const handleStepForward = () => {
    props.changeStep(2);
  };

  const handleMobileTopup = () => {
    if (handleValidation()) props.mobileTopup();
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const getRechargeAmounts = () =>
    props.amounts.map((amount) => (
      <span
        key={amount.value}
        onClick={() => props.changeRechargeForm('amount', amount)}
        className={clsx('recharge-amount', {
          'recharge-amount--selected':
            props.rechargeForm.amount.value === amount.value,
        })}
      >
        {amount.value}
      </span>
    ));
  const formattedNumber = () => {
    if (props.rechargeForm.number) {
      const splitNumber = props.rechargeForm.number.split(' ');
      if (splitNumber && splitNumber.length) {
        if (splitNumber.length === 1) {
          return props.rechargeForm.number;
        } else {
          return splitNumber[1];
        }
      }
    }
    return '';
  };

  const classes = useStyles();

  return (
    <div className="recharge">
      <div className="recharge-content">
        <div className="recharge-header">
          <h2>Ricarica singola</h2>
          <img
            src={IMAGE_UPLOADS_URL + props.operator.toLowerCase() + '_150.png'}
            alt="operator"
          />
        </div>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <label>{`Numero ${props.operator.toUpperCase()} da ricaricare`}</label>
              <div style={{ display: 'flex' }}>
                <SelectWithSearch
                  label="Prefisso"
                  selectValue={props.rechargeForm.countryCode}
                  error={errors.countryCode}
                  data={props.countryCodes}
                  handleSelectChangeProps={handleSelectChange}
                />
                <TextField
                  style={{ marginLeft: '10px' }}
                  label="Numero"
                  value={props.rechargeForm.number}
                  name="number"
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.number}
                  helperText={errors.number}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label>Conferma numero</label>
              <div style={{ display: 'flex' }}>
                <SelectWithSearch
                  label="Prefisso"
                  selectValue={props.rechargeForm.countryCode}
                  error={errors.countryCode}
                  data={props.countryCodes}
                  handleSelectChangeProps={handleSelectChange}
                />
                <TextField
                  style={{ marginLeft: '10px' }}
                  label="Numero"
                  name="confirmNumber"
                  value={props.rechargeForm.confirmNumber}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.confirmNumber}
                  helperText={errors.confirmNumber}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <label>Importo della ricarica</label>
              <div className="recharge-amount-container">
                {getRechargeAmounts()}
                {errors.amount && (
                  <span className="recharge-amount-error">{errors.amount}</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className="recharge-optional-email">
              <TextField
                label={`Inserisci il tuo Indirizzo mail per la notifica ${
                  props.isGuest ? '' : '(opzionale)'
                }`}
                name="optionalEmail"
                InputProps={{
                  type: 'email',
                }}
                onChange={handleInputChange}
                value={props.rechargeForm.optionalEmail}
                fullWidth
                error={!!errors.optionalEmail}
                helperText={errors.optionalEmail}
              />
            </Grid>
          </Grid>
        </Box>
        <Box my={3} className="recharge-privacy">
          <span>
            {showTooltip && !props.rechargeForm.privacy && (
              <Tooltip className="epay-tooltip">{TOOLTIP_TITLE}</Tooltip>
            )}
            <Checkbox
              checked={props.rechargeForm.privacy === true}
              onClick={() => {
                props.togglePrivacy();
              }}
              value={props.changeRechargeForm.privacy}
            />

            {/* <Checkbox
              checked={isAgreePrivacyPolicyChecked}
              color="primary"
              onChange={(event) =>
                setAgreePrivacyPolicyChecked(event.target.checked)
              }
              name="privacy-policy"
              inputProps={{
                'aria-label': 'uncontrolled-checkbox',
              }}
            /> */}
          </span>

          <span
            style={{
              fontSize: '12px',
              color: '#000',
            }}
          >
            Accetto le&nbsp;
            <a
              href="/condizioni"
              target="_blank"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              condizioni
            </a>
            &nbsp;del servizio e informativa&nbsp;
            <a
              href="/privacy"
              target="_blank"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy
            </a>
          </span>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {/* <Button
            variant="outlined"
            size="large"
            onClick={handleStepBack}
            style={{ textTransform: 'capitalize' }}
          >
            Indietro
          </Button> */}
          <div></div>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            disabled={props.isLoading}
            onClick={handleMobileTopup}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ textTransform: 'capitalize' }}
          >
            Procedi
          </Button>
        </Box>
      </div>
    </div>
  );
};

RechargeScreen.propTypes = {
  operator: PropTypes.string,
  rechargeForm: PropTypes.shape({
    number: PropTypes.string,
    confirmNumber: PropTypes.string,
    amount: PropTypes.shape({
      value: PropTypes.number,
      eanNO: PropTypes.string,
    }),
    optionalEmail: PropTypes.string,
    privacy: PropTypes.bool,
  }),
  changeStep: PropTypes.func,
  changeRechargeForm: PropTypes.func,
  togglePrivacy: PropTypes.func,
  amounts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      eanNO: PropTypes.string,
    })
  ),
  mobileTopup: PropTypes.func,
  goToPayment: PropTypes.func,
  reserveTransactionId: PropTypes.string,
  isLoading: PropTypes.bool,
};

export { RechargeScreen };
