import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';

import * as successAnimation from '../../../assets/animations/success.json';
import * as errorAnimation from '../../../assets/animations/error.json';

import Button from '../../../components/common/Button';
import history from '../../../utils/history';

import './style.scss';
import PaymentFeedback from '../../paymentFeedback';
import PaymentFailed from '../../paymentFailed';
import PaymentSuccess from '../../paymentSuccess';
import Commission from '../../../components/Commission';

const PinkButton = withStyles({
  root: {
    color: '#fff',
    backgroundColor: '#d93879',
    border: '0',
    borderColor: '#d93879',
    borderRadius: 0,
    fontWeight: 'normal',

    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#d93879',
      border: '1px solid',
      boxShadow: 'none',
      color: '#d93879',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#fff',
      border: '1px solid',
      borderColor: '#d93879',
    },
    '&:focus': {},
  },
})(Button);

const Success = ({
  supplier,
  amount,
  resetBackToInitialState,
  isUser,
  setDataForRedirectionAfterLogin,
  email,
}) => {
  useEffect(() => {
    return () => {
      resetBackToInitialState();
    };
  }, []);
  return (
    <>
      <h2 className="final-page-gift-card-purchase__heading">
        L’operazione è andata a buon fine!
      </h2>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: successAnimation.default,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={150}
        width={150}
      />
      <p>
        E’ stato effettuato un pagamento di: Gift Card {amount} a favore di{' '}
        {supplier || 'WIND'}
      </p>
      <span>
        Consulta la ricevuta in &nbsp;
        <Link
          to={isUser ? '/miei_pagamenti' : '/login'}
          style={{
            textDecoration: 'none',
            color: '#4a90e2',
            borderBottom: '1px solid #4a90e2',
          }}
          onClick={() => setDataForRedirectionAfterLogin(email)}
        >
          <span>I miei pagamenti</span>
        </Link>
      </span>
      <PinkButton
        style={{
          width: '250px',
          height: '40px',
          marginTop: '52px',
        }}
        onClick={() => {
          history.push('/');
        }}
      >
        {isUser ? 'Torna alla Home' : 'Accedi'}
      </PinkButton>
    </>
  );
};
const FinalPageGiftCardPurchase = ({
  resetIsCompleted,
  setScreen,
  supplier,
  amount,
  resetBackToInitialState,
  setDataForRedirectionAfterLogin,
  email,
  paymentState,
  transactionData,
  selectedCard,
  cards,
  user,
  goToPayment,
}) => {
  const [isFail, setIsFail] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUser, setIsUser] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (user && user._id) {
      setIsUser(true);
    } else {
      setIsUser(false);
    }
  }, [user]);
  useEffect(() => {
    if (transactionData) {
      setIsSuccess(false);
      setIsFail(false);
      setIsPending(false);
      console.log('transactionData', transactionData);
      if (transactionData && transactionData.status === 'fail') {
        setIsFail(true);
      } else if (transactionData && transactionData.status === 'pending') {
        setIsPending(true);
      } else {
        setIsSuccess(true);
      }
    }
  }, [transactionData]);

  // console.log('transactionData', transactionData);
  // if (transactionData) {
  // isSuccess =
  //   transactionData.status === 'paid' || transactionData.status === 'pending'
  //     ? true
  //     : false;
  // email = transactionData.email;
  // supplier = transactionData.supplier.toUpperCase();
  // }

  return (
    <div className="final guest">
      {isSuccess && <PaymentSuccess isUser={isUser} />}
      {isFail && <PaymentFailed isUser={isUser} />}
      {isPending && (
        <PaymentFeedback
          data={transactionData}
          goToPayment={goToPayment}
          isUser={isUser}
        />
      )}
      {isSuccess && (
        <Commission
          baseAmount={transactionData.productGift.faceValue}
          data={transactionData}
          last4Digits={
            selectedCard && selectedCard !== 'new' && user && user._id
              ? cards[
                  cards.findIndex((card) => card.stripeCardId === selectedCard)
                ].cardNumber
              : ''
          }
          typeOfPayment={transactionData.productName}
        />
      )}
    </div>
  );
};

export default FinalPageGiftCardPurchase;
