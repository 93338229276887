import React from 'react';
import DiagonalNavbar from '../../components/common/DiagonalNavbar';
import './style.scss';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy__container">
      <div className="privacy-policy__content">
        <b>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI</b>
        <br />
        <b>
          (Art. 13 del Regolamento UE 679/2016 - Regolamento generale sulla
          protezione dei dati){' '}
        </b>
        <br />
        <br />

        <p>
          Gentile Cliente,
          <br />
          <br />
          Condexo S.r.l, con sede legale in Roma, Piazza di Villa Carpegna, n.
          58., P.IVA 15033931005, (di seguito anche solo “<b>Condexo</b>” o la “
          <b>Società</b>
          ”) raccoglie, tratta e comunica a terzi i dati personali dei propri
          clienti (di seguito anche solo “Interessati”) che intrattengono
          rapporti pre-contrattuali e/o contrattuali con la stessa.
          <br />
          Condexo, in qualità di Titolare del trattamento dei dati, è tenuta a
          fornirLe precise informazioni sul trattamento dei Suoi dati personali
          e sui diritti a Lei spettanti, come previsto dall’art. 13 del
          Regolamento UE 2016/679 del 27 aprile 2016 – Regolamento generale
          sulla protezione dei dati (GDPR).
          <br />
          La invitiamo pertanto a leggere attentamente le informazioni che
          seguono per poter poi consapevolmente esprimere la Sua volontà in
          merito al trattamento dei Suoi dati.
        </p>
        <br />

        <ol>
          <li>
            <b>Definizioni</b>
            <p>
              <b>
                <i>Dato personale: &nbsp;</i>
              </b>
              qualsiasi informazione riguardante una persona fisica identificata
              o identificabile (Interessato). Si considera identificabile la
              persona fisica che può essere identificata, direttamente o
              indirettamente, con particolare riferimento ad un identificativo
              come il nome, un numero di identificazione, dati relativi
              all’ubicazione, un identificativo online o uno o più elementi
              caratteristici della sua identità fisica, fisiologica, genetica,
              psichica, economica, culturale o sociale;
              <br />
              <b>Trattamento: </b>
              qualsiasi operazione o insieme di operazioni, compiute con o senza
              l’ausilio di processi automatizzati e applicate a dati personali o
              insiemi di dati personali;
              <br />
              <b>Titolare del trattamento: </b>
              la persona fisica o giuridica che determina le finalità e i mezzi
              del trattamento di dati personali;
              <br />
              <b>Responsabile del trattamento:</b>
              la persona fisica o giuridica che tratta dati personali per conto
              del Titolare del trattamento;
            </p>
          </li>
          <li>
            <b>Titolare del trattamento</b>
            <p>
              Titolare del trattamento è Condexo S.R.L., in persona del legale
              rappresentante pro tempore, con sede legale in Roma, Piazza di
              Villa Carpegna, n. 58., P.IVA 15033931005 (email:
              info@condexo.it).
            </p>
          </li>
          <li>
            <b>Finalità del trattamento</b>
            <p>
              {' '}
              I dati personali dell’Interessato (ossia i Suoi dati) sono da Lei
              stesso forniti a Condexo e sono da quest’ultima trattati per le
              seguenti finalità:
            </p>

            <ol type="a">
              <li>
                per finalità strettamente connesse e strumentali all’esecuzione
                degli obblighi derivanti dai rapporti contrattuali instaurati
                con Condexo o per lo svolgimento di attività pre-contrattuali,
                alla gestione dei rapporti con gli Interessati (es. acquisizioni
                di informazioni preliminari alla conclusione di un contratto,
                verifiche e valutazioni sulle risultanze e sull’andamento dei
                rapporti, nonché sui rischi ad essi connessi), nonché per
                finalità di tipo amministrativo-contabile. Base giuridica del
                trattamento è l’art. 6, comma 1, lett. b, del GDPR;
              </li>
              <li>
                per adempiere o esigere l’adempimento degli obblighi previsti
                dalla legge, da regolamenti, dalla normativa comunitaria (tra
                cui, a titolo esemplificativo e non esaustivo, obblighi in
                materia di antiriciclaggio, usura, accertamenti fiscali e
                tributari) nonché connessi agli obblighi derivanti da
                disposizioni impartite da autorità giudiziarie, da funzionari
                amministrativi o da organi di polizia (quali, a titolo
                esemplificativo, funzionari della Guardia di Finanza) a ciò
                legittimati dalla legge o da organi di vigilanza e di controllo.
                Base giuridica del trattamento è l’art. 6, comma 1, lett. c, del
                GDPR; Il conferimento dei dati personali per le finalità di cui
                alle lettere a) e b) che precedono è un requisito necessario per
                la conclusione e l’esecuzione del contratto e il rifiuto di
                fornirli comporta l’impossibilità per Condexo di instaurare e
                dare esecuzione ai rapporti in oggetto;
              </li>
              <li>
                per finalità di marketing che costituiscono un legittimo
                interesse del Titolare. Base giuridica del trattamento è l’art.
                6, comma 1, lett. f, del GDPR. Il trattamento dei dati personali
                per le finalità di cui al punto c) che precede (finalità
                aggiuntiva di marketing), da parte di Condexo è meramente
                eventuale e assoggettato, nel caso in cui ciò si verifichi, alla
                richiesta del conferimento di esplicito consenso preventivo da
                parte dell’Interessato. Ove richiesto e/o ove prestato, il
                consenso sarà revocabile in qualsiasi momento, secondo le
                modalità riportate nel successivo Punto 5.
              </li>
            </ol>
          </li>
          <li>
            <b>Comunicazione a terzi e diffusione</b>
            <p>
              Condexo può essere chiamata a comunicare i dati personali degli
              Interessati in ottemperanza ad obblighi previsti dalla normativa
              comunitaria, da leggi e regolamenti, a:
            </p>
            <ol type="a">
              <li>
                organi di vigilanza e controllo in forza di provvedimenti dagli
                stessi emanati ovvero stabiliti da prassi amministrative o da
                regolamenti.
              </li>
              <li>
                soggetti terzi nominati Responsabili del trattamento secondo
                quanto previsto dall’art. 28 del GDPR, del cui supporto Condexo
                si avvale per le finalità di cui al punto 3 che precede e, cioè
                banche ed istituti finanziari, operanti anche all’estero; call
                center e soggetti fornitori di servizi generali (es.
                archiviazione contratti, stampa imbustamento e
                spedizione/consegna comunicazioni/carte/codici ecc.); soggetti
                fornitori di servizi tecnologici del cui supporto si avvale
                Condexo; professionisti, società esterne di recupero crediti e
                società per la revisione del proprio bilancio. I dati non
                saranno diffusi. Non ne è previsto il trasferimento verso Paesi
                Terzi.
              </li>
            </ol>
          </li>
          <li>
            <b>
              Diritti dell’Interessato <br />
            </b>
            L’Interessato, ai sensi degli articoli da 15 a 22 del GDPR, ha
            diritto:
            <ol type="a">
              <li>All’accesso ai propri dati personali (art. 15 GDPR)</li>
              <li>
                Ad ottenere la rettifica dei dati personali inesatti che lo
                riguardano (art. 16 GDPR).
              </li>
              <li>
                Ad ottenere la cancellazione dei dati personali che lo
                riguardano senza ingiustificato ritardo alle condizioni indicate
                all’art. 17 GDPR
              </li>
              <li>
                Alla limitazione del trattamento, in presenza delle condizioni
                di cui all’art. 18 GDPR;
              </li>
              <li>L’interessato ha inoltre diritto:</li>
              <li>
                di opporsi in qualsiasi momento al trattamento dei suoi dati
                personali trattati per finalità di marketing diretto e al
                trattamento di profilazione, nella misura in cui sia connesso a
                tale marketing diretto (art. 21 GDPR);
              </li>
              <li>
                nel caso in cui il trattamento ha la propria base giuridica nel
                consenso, di revocare il consenso in qualsiasi momento, senza
                alcun pregiudizio per la liceità del trattamento basata sul
                consenso prima della revoca;
              </li>
              <li>
                (Il diritto della portabilità dei dati) di ricevere i propri
                dati personali forniti al titolare, in un formato strutturato,
                di uso comune e leggibile da dispositivo automatico e di
                trasmetterli ad un diverso titolare senza impedimenti da parte
                del titolare del trattamento cui li ha forniti. Nell’esercitare
                i propri diritti relativamente alla portabilità dei dati
                l’Interessato ha anche il diritto di ottenere la trasmissione
                diretta dei dati personali da un titolare del trattamento ad un
                altro, se tecnicamente fattibile. Le richieste di cui al
                presente paragrafo possono essere presentate per iscritto al
                Titolare anche mediante messaggio di posta elettronica inviato
                all’indirizzo privacy@condexo.it.
              </li>
            </ol>
          </li>
          <li>
            <b>
              Conservazione dei dati <br />
            </b>
            I dati personali acquisiti e trattati da Condexo per le finalità
            sopra indicate ai punti 3 a) e b) verranno conservati per il tempo
            strettamente necessario per dare esecuzione al rapporto
            precontrattuale e contrattuale sorto con Condexo, dopodiché saranno
            cancellati o resi anonimi, fatti salvi gli obblighi di legge in tema
            di conservazione (ad esempio, obblighi civilistici, fiscali,
            antiriciclaggio e in tema di conservazione delle informazioni
            relative alle transazioni finanziarie.).
          </li>
          <li>
            <b>Reclami</b>
            <br />È previsto il diritto di proporre reclamo all’Autorità di
            controllo. Per l’Italia è il Garante per la protezione dei dati
            Personali (
            <a
              href="http://www.garanteprivacy.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.garanteprivacy.com
            </a>
            ).
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
