const images = {};
// ==== free sign up ====
images.freeSignUp1 = require('./free-sign-up__1.svg');
images.freeSignUp2 = require('./free-sign-up__2.svg');
images.freeSignUp3 = require('./free-sign-up__3.svg');
images.freeSignUp4 = require('./free-sign-up__4.svg');

// ==== landing =====
images.landingBulletin = require('./landing/bulletin.svg');
images.landingRate = require('./landing/rate.svg');
images.landingMavRav = require('./landing/mavRav.svg');
images.landingPayByPhone = require('./landing/payByPhone.svg');
images.landingGiftCard = require('./landing/giftCard.svg');
images.paymentAccepted = require('./landing/paymentAccepted.svg');
// === dashboard =====
images.bollettini = require('./dashboard/bollettini.svg');
images.mavRav = require('./dashboard/mavRav.svg');
images.rate = require('./dashboard/rate.svg');
images.epay = require('./dashboard/epay.svg');

// ==== credit cards =====
images.creditCardMaster = require('../icons/credit-cards/master-card.png');
images.creditCardVisa = require('../../assets/icons/credit-cards/visa.png');
images.creditCardAmericanExpress = require('../../assets/icons/credit-cards/american-express.png');
images.creditCardDefault = require('../../assets/icons/credit-cards/default-card.png');
images.cvvInfo = require('../../assets/icons/credit-cards/cvv-info.jpg');

// ==== epay =====
images.free = require('./epay/free.svg');
images.immediate = require('./epay/immediate.svg');
images.secure = require('./epay/secure.svg');
images.tim = require('./epay/tim.svg');
images.vodafone = require('./epay/vodafone.svg');
images.wind = require('./epay/wind.svg');
images.rechargeSuccess = require('./epay/success.svg');
images.rechargeFailed = require('./epay/failed.svg');
export default images;
