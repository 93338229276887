import React from 'react';
import LogoImageWhite from '../../../assets/images/logoAlt.png';
import './DiagonalNavbar.scss';
const DiagonalNavbar = ({ history }) => {
  const handleLogoClick = () => {
    // history.push('/');
    const homeURL = `${window.location.origin}/`;
    window.location.href = homeURL;
  };
  return (
    <div className="diagonal-navbar">
      <img
        src={LogoImageWhite}
        alt="logo"
        onClick={handleLogoClick}
        height="50"
      />
    </div>
  );
};

export default DiagonalNavbar;
