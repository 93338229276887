import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  button: {
    marginTop: 20, //da sostituire in futuro con lo space del tema prima del grid
    color: '#fff',
    background: '#fdbd39ff',
    fontWeight: '600',
    borderRadius: 50,
    padding: '6px 20px',
    '&:hover': {
      background: '#fd9d39',
      boxShadow: 'none',
    },
  },
  grid: {
    // minHeight: 'calc(100vh - 20px)',
  },
  paper: {
    margin: 'auto',
    maxWidth: '100vh',
    textAlign: 'center',
    borderRadius: 10,
    display: 'flex',
    padding: 60,
    width: '43em',
  },
  typography: {
    color: '#1a315b',
    h5: {
      fontWeight: 500,
    },
    navbar: {
      background: '#fff',
    },
  },
}));

const PaymentFailed =  (props) => {
  const classes = useStyles();
  const {isUser} = props
  const goTo = () => {
    if (isUser) {
      window.location.href = '/dashboard';
    } else {
      window.location.href = '/';
    }
  };
  return (
    <div>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <Paper elevation={4} className={classes.paper}>
          <Grid container container direction="row" justifyContent="center">
            <Grid item xs container direction="column">
              <Grid item xs>
                <Typography
                  className={classes.typography}
                  gutterBottom
                  variant="h5"
                >
                  <b>Operazione non eseguita</b>
                </Typography>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  className={classes.typography}
                >
                  La transazione non è stata portata a termine
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  className={classes.typography}
                >
                  ti consigliamo di ripetere l’operazione
                </Typography>
              </Grid>
              <Grid item xs>
                <Button
                  className={classes.button}
                  onClick={goTo}
                >
                  TORNA INDIETRO
                </Button>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default PaymentFailed;
