import React, { useState, useEffect } from 'react';
import UIActions from '../../redux/reducers/UIRedux';
import { AuthSelectors } from '../../redux/reducers/AuthRedux';
import PayYourBillActions, {
  PayYourBillSelectors,
} from '../../redux/reducers/PayYourBillRedux';
import MyProfileActions, {
  MyProfileSelectors,
} from '../../redux/reducers/MyProfileRedux';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { Page, PageContent } from '../layout';
// import history from '../../utils/history';
import { ProgressBar } from '../../components/ProgressBar';

import Header from './Header.jsx';
import Bollettino from './Bollettino';
import MavRav from './MavRav';
import Rata from './Rata';
import ScanCode from './ScanCode';
import FlashMessage from '../../components/common/FlashMessage';
import { Loader } from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import './PayYourBill.scss';
import GiftCardActions from '../../redux/reducers/GiftCardRedux';

const PayYourBill = ({
  showNavbar,
  history,
  user,
  isNewUser,
  reserveBillRequest,
  getProfileDetailsRequest,
  setBollettinoKey,
  setMavRavKey,
  myProfile,
  activeVariant,
  setActiveVariant,
  clearMessages,
  errorMessage,
  successMessage,
  resetState,
  isLoadingPB,
  isLoadingAC,
  getBillTransactionDataById,
  transactionData,
}) => {
  // const [activeVariant, setActiveVariant] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [showScanCode, setShowScanCode] = useState(false);

  // Alert
  const [alert, setAlert] = useState({ show: false, variant: '', error: '' });
  const [isRun, setIsRun] = useState(false);

  const setAndHideAlert = (message, hasErrors) => {
    setAlert({
      ...alert,
      show: true,
      variant: hasErrors ? 'danger' : 'success',
      error: message,
    });
    hideAlert();
  };
  const hideAlert = () => {
    setTimeout(() => {
      setAlert({ ...alert, show: false });
    }, 5000);
  };

  useEffect(() => {
    if (!isRun) {
      setIsRun(true);
      return;
    }
    if (alert.show === false) {
      if (errorMessage) {
        setAndHideAlert(errorMessage, true);
      }
    }
  }, [errorMessage, isRun, setAndHideAlert]);

  useEffect(() => {
    if (window.innerWidth < 767) {
      //  setShowScanCode(true);
    }
  }, [window.innerWidth]);

  const [success, setSuccess] = useState(null);
  const [transactionId, setTransactionId] = useState(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const paramsSuccess = urlSearchParams.get('success');
    const paramsTransactionId = urlSearchParams.get('t');

    setSuccess(paramsSuccess === null ? paramsSuccess : Number(paramsSuccess));
    setTransactionId(paramsTransactionId);
    if (paramsSuccess !== null) {
      setActiveStep(3);
    }
    console.log('activeStep', activeStep);
  }, [activeStep]);
  /*   useEffect(() => {
    // console.log(history);
    console.log('histroy updated', history);
    if (history) {
      setActiveVariant(history.location.pathname.substring(1));
    }
  }, [history]);*/
  useEffect(() => {
    if (transactionId) {
      getBillTransactionDataById(transactionId);
    }
  }, [transactionId]);
  useEffect(() => {
    showNavbar();
  }, [showNavbar]);

  useEffect(() => {
    if (history) {
      console.log(
        '🚀 ~ file: index.js ~ line 38 ~ useEffect ~ history',
        history.location
      );
    }
    if (history && history.location && history.location.pathname) {
      console.log('SET ACTIVE VARIANT FROM ROOT FIRED');
      setActiveVariant(history.location.pathname.substring(1));
    }
  }, []);

  const exitFlow = () => {
    history.push('/dashboard');
  };

  useEffect(() => {
    console.log(
      'Pay Your Bill transactionData',
      transactionData,
      activeVariant
    );
    if (transactionData) {
      const splitAmount = (
        (transactionData.amount * 100 +
          transactionData.stripeCommissionAmount +
          transactionData.condexoCommissionAmount +
          200) /
        100
      )
        .toFixed(2)
        .split('.');
      if (activeVariant === 'mav-rav' || activeVariant === 'rata__mav-rav') {
        setMavRavKey('stepTwo', 'name', transactionData.firstName);
        setMavRavKey('stepTwo', 'surname', transactionData.surname);
        setMavRavKey('stepTwo', 'email', transactionData.email);
        setMavRavKey('stepOne', 'amountToLeftOfDecimal', splitAmount[0]);
        setMavRavKey('stepOne', 'amountToRightOfDecimal', splitAmount[1]);
      }
      if (
        activeVariant === 'bollettini' ||
        activeVariant === 'rata__bollettini'
      ) {
        setBollettinoKey('stepOne', 'type', transactionData.typeOfBollettini);
        setBollettinoKey('stepOne', 'accountNo', transactionData.numero);
        setBollettinoKey('stepOne', 'code', transactionData.typeOfBollettini);
        setBollettinoKey('stepOne', 'desc', transactionData.causale);
        setBollettinoKey('stepOne', 'amountToLeftOfDecimal', splitAmount[0]);
        setBollettinoKey('stepOne', 'amountToRightOfDecimal', splitAmount[1]);
        setBollettinoKey('stepTwo', 'name', transactionData.firstName);
        setBollettinoKey('stepTwo', 'surname', transactionData.surname);
        setBollettinoKey('stepTwo', 'email', transactionData.email);
        setBollettinoKey('stepTwo', 'address', transactionData.address);
        setBollettinoKey('stepTwo', 'city', transactionData.city);
        setBollettinoKey('stepTwo', 'district', transactionData.district);
        setBollettinoKey('stepTwo', 'postalCode', transactionData.postalCode);
      }
    }
  }, [transactionData]);

  useEffect(() => {
    console.log('MOUNTED!');
    console.log(user);

    if (user && user._id && !isEmpty(myProfile)) {
      if (activeVariant === 'mav-rav' || activeVariant === 'rata__mav-rav') {
        setMavRavKey('stepTwo', 'name', myProfile.name);
        setMavRavKey('stepTwo', 'surname', myProfile.surname);
        setMavRavKey('stepTwo', 'mobileNo', myProfile.phoneNumber);
        setMavRavKey('stepTwo', 'email', myProfile.email);
      }
      if (
        activeVariant === 'bollettini' ||
        activeVariant === 'rata__bollettini'
      ) {
        setBollettinoKey('stepTwo', 'name', myProfile.name);
        setBollettinoKey('stepTwo', 'surname', myProfile.surname);
        setBollettinoKey('stepTwo', 'email', myProfile.email);
        setBollettinoKey('stepTwo', 'address', myProfile.address);
        setBollettinoKey('stepTwo', 'city', myProfile.city);
        setBollettinoKey('stepTwo', 'district', myProfile.district);
        setBollettinoKey('stepTwo', 'postalCode', myProfile.postalCode);
      }
    }
  }, [activeVariant, user, myProfile]);

  useEffect(() => {
    if (user && user._id) {
      getProfileDetailsRequest();
    }
  }, [user]);

  useEffect(
    () => () => {
      console.log('PAY YOUR BILL UNMOUNTING...');
      resetState();
    },
    []
  );

  //BANNER
  const BannerForGuest = () => {
    const redirectToLogin = () => {
      history.push('/login');
    };
    return (
      <>
        {activeVariant === 'rata' && (
          <div
            style={{
              textAlign: 'center',
              color: '#224670',
              fontWeight: 600,
            }}
          >
            <p>
              Seleziona la modalità di pagamento della tua rata condominiale
              scegliendo tra bollettino o Mav/Rav
            </p>
          </div>
        )}
      </>
    );
  };

  const showBanner = activeStep === 0 && !(user && user._id);
  const hideScanCode = () => {
    setShowScanCode(false);
  };
  const redirectToLogin = () => {
    history.push('/login');
  };
  //prova remove link
  const showLinkDashboard = activeStep === 0 && !(user && user._id);
  const makeScanCodeVisible = () => {
    setShowScanCode(true);
    const redirectToLogin = () => {
      history.push('/login');
    };
  };
  console.log('activeVariant', activeVariant);
  console.log('transactionData', transactionData);
  return (
    <Page>
      <PageContent className="pay-your-bill__page">
        {(isLoadingPB || isLoadingAC) && <Loader belowNavbar />}
        {showScanCode && (
          <ScanCode hideModal={hideScanCode} updateKeys={setBollettinoKey} />
        )}
        {!showScanCode && (
          <>
            {/*  {user && (*/}
            <Header exitFlow={exitFlow} activeVariant={activeVariant} />
            {/*            )}*/}
            {showBanner && BannerForGuest()}
            {activeVariant !== 'rata' && (
              <ProgressBar totalSteps={3} activeStep={activeStep} />
            )}
            {activeVariant !== 'rata' && activeStep === 0 && (
              <div
                className="pay-your-bill__scan-code-banner"
                onClick={makeScanCodeVisible}
              >
                <span className="pay-your-bill__scan-code-banner__label">
                  Effettua il pagamento scannerizzando il codice!
                </span>
                <div className="pay-your-bill__scan-code-banner__icon__container">
                  <FontAwesomeIcon
                    icon={faCamera}
                    className="pay-your-bill__scan-code-banner__icon"
                  />
                </div>
              </div>
            )}
            {(activeVariant === 'bollettini' ||
              activeVariant === 'rata__bollettini') && (
              <Bollettino
                activeVariant={activeVariant}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                reserveBillRequest={reserveBillRequest}
                makeScanCodeVisible={makeScanCodeVisible}
                transactionData={transactionData}
              />
            )}
            {activeVariant === 'rata' && (
              <Rata
                activeVariant={activeVariant}
                setActiveVariant={setActiveVariant}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                reserveBillRequest={reserveBillRequest}
                transactionData={transactionData}
              />
            )}
            {(activeVariant === 'mav-rav' ||
              activeVariant === 'rata__mav-rav') && (
              <MavRav
                activeVariant={activeVariant}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                reserveBillRequest={reserveBillRequest}
                transactionData={transactionData}
              />
            )}
            {!user && (
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: 60,
                  color: '#3fbfff',
                  fontWeight: 700,
                  background: 'rgb(223 244 255)',
                  padding: 60,
                  marginTop: 51,
                }}
              >
                <p onClick={redirectToLogin} style={{ cursor: 'pointer' }}>
                  Fai <u>login</u> per visualizzare le ricevute dei tuoi
                  acquisti e tutte le tue operazioni
                </p>
              </div>
            )}
          </>
        )}
      </PageContent>

      {alert.show && (
        <FlashMessage
          message={alert.error}
          hideFlashMessage={() => {
            clearMessages();
            setAlert({ show: false, variant: '', error: '' });
          }}
          variant={alert.variant}
        />
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  user: AuthSelectors.selectCurrentUser(state),
  myProfile: MyProfileSelectors.selectProfile(state),
  activeVariant: PayYourBillSelectors.selectActiveVariant(state),
  errorMessage: PayYourBillSelectors.selectErrorMessage(state),
  successMessage: PayYourBillSelectors.selectSuccessMessage(state),
  transactionData: PayYourBillSelectors.selectTransactionData(state),
  isLoadingPB: PayYourBillSelectors.selectIsLoading(state),
  isLoadingAC: MyProfileSelectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  showNavbar: () => dispatch(UIActions.showNavbar()),
  reserveBillRequest: () => dispatch(PayYourBillActions.reserveBillRequest()),
  getProfileDetailsRequest: () =>
    dispatch(MyProfileActions.getProfileDetailsRequest()),
  setMavRavKey: (stepCount, key, value) =>
    dispatch(PayYourBillActions.setMavRavKey(stepCount, key, value)),
  setBollettinoKey: (stepCount, key, value) =>
    dispatch(PayYourBillActions.setBollettinoKey(stepCount, key, value)),
  setActiveVariant: (variant) =>
    dispatch(PayYourBillActions.setActiveVariant(variant)),
  clearMessages: () => dispatch(PayYourBillActions.clearMessages()),
  getBillTransactionDataById: (transactionId) =>
    dispatch(PayYourBillActions.getBillTransactionRequest(transactionId)),
  resetState: () => dispatch(PayYourBillActions.resetState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayYourBill);
