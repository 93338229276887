import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Lottie from 'react-lottie';
import * as successAnimation from '../../../../assets/animations/success.json';
import * as errorAnimation from '../../../../assets/animations/error.json';
import icons from '../../../../assets/icons';
import GuestFinalScreen from './GuestFinalScreen';
import './FinalScreen.scss';
import PaymentSuccess from '../../../paymentSuccess';
import PaymentFailed from '../../../paymentFailed';
import PaymentFeedback from "../../../paymentFeedback";
import Commission from "../../../../components/Commission";

const FinalScreen = (props) => {
  const [isFail, setIsFail] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const { transactionData } = props;
  useEffect(() => {
    if (props.user && props.user._id) {
      setIsUser(true)
    } else {
      setIsUser(false)
    }
  }, [props.user])

  useEffect(() => {
    if (transactionData && transactionData.status) {
      setIsSuccess(false);
      setIsFail(false);
      setIsPending(false);
      console.log('transactionData', transactionData);
      if (transactionData && transactionData.status === 'fail') {
        setIsFail(true);
      } else if (transactionData && transactionData.status === 'pending') {
        setIsPending(true);
      } else {
        setIsSuccess(true);
      }
    }
  }, [props.transactionData]);

  return (
      <div className="final guest" >
        {/* <div className="final-content"> */}
        {isSuccess && <PaymentSuccess isUser={isUser} />}
        {isFail && <PaymentFailed isUser={isUser} />}
        {isPending && <PaymentFeedback data={transactionData} goToPayment={props.goToPayment}
                                       isUser={isUser} />}
        {/* {rechargeStatus
              ? 'Ricarica effettuata correttamente!'
              : 'Qualcosa è andato storto, verifica che i dati inseriti siano corretti.'}
          </h2>
          {rechargeStatus ? (
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: successAnimation.default,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              style={{
                margin: '40px 0 50px',
              }}
              height={150}
              width={150}
            />
          ) : (
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: errorAnimation.default,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              style={{
                margin: '40px 0 50px',
              }}
              height={120}
              width={120}
            />
          )} */}
        {/*  <img
          src={
            isRechargeSuccessfull()
              ? icons.rechargeSuccess
              : icons.rechargeFailed
          }
          alt="recharge"
        /> */}
        {/* <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              props.goToDashboard();
            }}
          >
            {rechargeStatus ? 'Torna alla Home' : 'Inditetro'}
          </Button> */}
        {/* </div> */}
        {isSuccess && (
            <Commission
                baseAmount={props.baseAmount}
                data={props.transactionData}
                last4Digits={
                  props.selectedCard && props.selectedCard !== 'new' && props.user && props.user._id
                      ? props.cards[
                          props.cards.findIndex((card) => card.stripeCardId === props.selectedCard)
                          ].cardNumber
                      : ''
                }
                typeOfPayment={props.transactionData.productName}
            />
        )}
      </div>
  );
};

FinalScreen.propTypes = {
  rechargeStatus: PropTypes.oneOf(['success', 'failed']),
  goBack: PropTypes.func,
};

export { FinalScreen };
