import { put, call, select } from 'redux-saga/effects';
import GiftCardActions, {
  getGiftTransactionRequest,
  GiftCardSelectors,
  goToPaymentGiftCardSuccess,
} from '../reducers/GiftCardRedux';
import isEmpty from 'lodash/isEmpty';
import AuthActions, { AuthSelectors } from '../reducers/AuthRedux';
import {
  getStripeCommissionAmount,
  getCondexoCommissionAmount,
  getTotalInclusiveOfCommissions,
} from '../../utils/commissions';
import format from 'date-fns/format';
import EpayActions from '../reducers/EpayRedux';

const getErrorMessage = (response) =>
  !isEmpty(response.data) && !isEmpty(response.data.errors)
    ? response.data.errors.message
    : 'Something went wrong.';

export function* getGiftCardList(api, { searchQuery }) {
  const response = yield call(api.giftCardList, searchQuery);
  switch (response.status) {
    case 200:
      yield put(
        GiftCardActions.getGiftCardListSuccess(
          response.data.sort((a, b) => {
            if (a.supplier < b.supplier) {
              return -1;
            }
            if (a.supplier > b.supplier) {
              return 1;
            }
            return 0;
          })
        )
      );
      break;
    case 401:
      yield put(GiftCardActions.getGiftCardListFailed('Sessione Scaduta'));
      break;
    case 400:
    default:
      yield put(
        GiftCardActions.getGiftCardListFailed(getErrorMessage(response))
      );
  }
}

export function* getGiftTransactionDataById(api, action) {
  console.log('action', action);

  const { transactionId } = action;
  const response = yield call(api.getTransactionById, {
    transactionId,
  });

  switch (response.status) {
    case 200:
      yield put(GiftCardActions.getGiftTransactionSuccess(response.data));
      break;
    case 401:
      yield put(GiftCardActions.getGiftTransactionFailed('Sessione Scaduta'));
      break;

    case 400:
    default:
      yield put(
        GiftCardActions.getGiftTransactionFailed(getErrorMessage(response))
      );
  }
}

export function* topUpGiftCard(api, { data }) {
  console.log('Data--');
  const { paymentSource } = data;

  // select me user
  let mobile = '',
    userId = '',
    stripeCustomerId = '',
    ownEmail = '';
  const authResponse = yield select(AuthSelectors.selectCurrentUser);
  const isGuest = !(authResponse && authResponse._id);
  console.log(
    '🚀 ~ file: GiftCardSaga.js ~ line 55 ~ function*topUpGiftCard ~ isGuest',
    { isGuest }
  );

  if (!isEmpty(authResponse)) {
    mobile = authResponse.phoneNumber;
    userId = authResponse._id;
    stripeCustomerId = authResponse.stripeCustomerId;
    ownEmail = authResponse.email;
  }

  /* const {
    phoneNumber: mobile,
    _id: userId,
    stripeCustomerId,
    email: ownEmail,
  } = yield select(AuthSelectors.selectCurrentUser); */
  console.log('--- INSIDE SAGA', mobile, userId);

  // select giftcaredrequestobj
  const { email, amount, desc: description, amazonId, source } = yield select(
    GiftCardSelectors.selectTopUpGiftCardRequestObj
  );

  const { supplier } = yield select(GiftCardSelectors.selectActiveGiftCard);

  const { productType: type, product: productName, eanNo } = yield select(
    GiftCardSelectors.selectActiveProduct
  );

  // select issue and development
  console.log(data);
  const currentDate = new Date();
  const response = yield call(
    api.topUpGiftCard,
    {
      mobile,
      type,
      eanNo,
      amount: getTotalInclusiveOfCommissions(amount),
      topUpAmount: amount,
      condexoCommissionAmount: getCondexoCommissionAmount(),
      stripeCommissionAmount: getStripeCommissionAmount(amount),
      productName,
      supplier,
      amazonId,
      description,
      source,
      // only logged in user
      ...(isGuest
        ? {
            email: email || '',
            stripeToken: paymentSource,
          }
        : {
            userId,
            email: email || ownEmail,
            stripeCustomerId,
            paymentSource,
          }),
      tStamp: format(currentDate, 'dd/MM/yyyy k:m'),
    },
    isGuest
  );
  switch (response.status) {
    case 200:
      yield put(GiftCardActions.topUpGiftCardSuccess(response.data));
      break;
    case 401:
      yield put(GiftCardActions.topUpGiftCardFailed('Sessione Scaduta'));
      break;

    case 400:
    default:
      console.log('error', response);
      yield put(GiftCardActions.topUpGiftCardFailed(getErrorMessage(response)));
  }
}
export function* goToPaymentGiftCard(api, action) {
  console.log('goToPaymentGiftCard', api, action);
  /* const { _id: userId, email } = yield select(AuthSelectors.selectCurrentUser);
    const response = yield call(api.mobileTopup, {
      ...action.data,
      userId,
      email: action.data.email || email,
    }, false); */
  let userId = '';
  let email = '';
  const authResponse = yield select(AuthSelectors.selectCurrentUser);
  if (!isEmpty(authResponse)) {
    userId = authResponse._id;
    email = authResponse.email;
  }
  const isGuest = !(authResponse && authResponse._id);
  const response = yield call(
    api.goToPayment,
    {
      ...action.data,
      ...(!isGuest && { userId }),
      email: action.data.email || email,
    },
    isGuest
  );

  switch (response.status) {
    case 200:
      yield put(GiftCardActions.goToPaymentGiftCardSuccess(response.data));
      break;
    case 401:
      yield put(GiftCardActions.goToPaymentGiftCardFailed('Sessione Scaduta'));
      break;

    case 400:
    default:
      yield put(
        GiftCardActions.goToPaymentGiftCardFailed(getErrorMessage(response))
      );
  }
}
