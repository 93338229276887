const obj = [
  {
    supplier: 'Amazon',
    websiteURL: 'https://www.amazon.it/',
    logo: 'amazon.png',
    products: [
      {
        _id: '5f0c10c158da204792b42517',
        product: 'Amazon Pin 10€',
        productType: 'PIN',
        faceValue: 10,
        model: 'B&S',
        eanNo: '4260354156891',
      },
      {
        _id: '5f0c10c258da204792b42518',
        product: 'Amazon Pin 25€',
        productType: 'PIN',
        faceValue: 25,
        model: 'B&S',
        eanNo: '4260354156907',
      },
      {
        _id: '5f0c10c358da204792b42519',
        product: 'Amazon Pin 50€',
        productType: 'PIN',
        faceValue: 50,
        model: 'B&S',
        eanNo: '4260354156914',
      },
      {
        _id: '5f0c10c358da204792b4251a',
        product: 'Amazon Pin 100€',
        productType: 'PIN',
        faceValue: 100,
        model: 'B&S',
        eanNo: '4260354156921',
      },
      {
        _id: '5f0c10c458da204792b4251b',
        product: 'Amazon Ricarica',
        productType: 'Ricarica online',
        faceValue: 0,
        model: 'B&S',
        eanNo: '0085143200707',
      },
    ],
  },

  {
    supplier: 'SKY',
    websiteURL: 'https://www.sky.it/',
    logo: 'sky_italia.png',
    products: [
      {
        _id: '5f0c10c658da204792b4251f',
        product: 'Sky 15 Eur',
        productType: 'PIN',
        faceValue: 15,
        model: 'B&S',
        eanNo: '8033005000784',
      },
      {
        _id: '5f0c10c658da204792b42520',
        product: 'Sky 25 Eur',
        productType: 'PIN',
        faceValue: 25,
        model: 'B&S',
        eanNo: '8033005000791',
      },
      {
        _id: '5f0c10c658da204792b42521',
        product: 'Sky 50 Eur',
        productType: 'PIN',
        faceValue: 50,
        model: 'B&S',
        eanNo: '8033005000807',
      },
    ],
  },
  {
    supplier: 'NETFLIX',
    websiteURL: 'https://www.netflix.com/it/',
    logo: 'netflix.png',
    products: [
      {
        _id: '5f0c10f658da204792b425a6',
        product: 'NETFLIX 25',
        productType: 'PIN',
        faceValue: 25,
        model: 'Agenzia',
        eanNo: '4251404504038',
      },
      {
        _id: '5f0c10f758da204792b425a7',
        product: 'NETFLIX 50',
        productType: 'PIN',
        faceValue: 50,
        model: 'Agenzia',
        eanNo: '4251404504045',
      },
    ],
  },
];

export default obj;
