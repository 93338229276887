import React, { useState, useRef } from 'react';
import LogoImageWhite from '../../assets/images/logoAlt.png';
import LogoImageDark from '../../assets/images/logo-dark.png';
import { Link } from 'react-router-dom';
import './Navbar.scss';
import { smoothScroll } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import history from '../../utils/history';
import { connect } from 'react-redux';
import AuthActions, { AuthSelectors } from '../../redux/reducers/AuthRedux';
import GiftCardActions from '../../redux/reducers/GiftCardRedux';
import { Button, withTheme } from '@material-ui/core';

const Navbar = (props) => {
  const { currentUser, hideLinks } = props;
  const [isLoggedInUserMenuVisible, setLoggedInUserMenuVisibility] = useState(
    false
  );
  const loggedInUserMenu = useRef(null);
  const isLoggedIn = !!currentUser && !!currentUser.token;
  const showLoggedInUserMenu = () => {
    setLoggedInUserMenuVisibility(true);
    document.addEventListener('click', hideLoggedInUserMenu);
  };

  const hideLoggedInUserMenu = () => {
    document.removeEventListener('click', hideLoggedInUserMenu);
    setLoggedInUserMenuVisibility(false);
  };

  const redirectToHomepage = () => {
    const homeURL = `${window.location.origin}${
      isLoggedIn ? '/dashboard' : '/'
    }`;
    window.location.href = homeURL;
    // history.push(homeURL)
  };

  return (
    <div className="navbar--container" style={{ backgroundColor: '#fff' }}>
      <div className="navbar">
        <img
          style={{ cursor: 'pointer' }}
          src={LogoImageDark}
          onClick={redirectToHomepage}
          alt="CondexoPay"
          height="50"
        />

        {isLoggedIn ? (
          <>
            <FontAwesomeIcon
              size="3x"
              icon={faUserCircle}
              className="navbar--user-icon"
              onClick={() => {
                if (isLoggedInUserMenuVisible) {
                  hideLoggedInUserMenu();
                } else {
                  showLoggedInUserMenu();
                }
              }}
            />
            {isLoggedInUserMenuVisible && (
              <div className="logged-in-user-menu" ref={loggedInUserMenu}>
                <span
                  onClick={() =>
                    history.push('/profilo') || props.resetBackToInitialState()
                  }
                >
                  Profilo
                </span>
                <span
                  onClick={() => {
                    props.setLoggedOut();
                    history.push('/login');
                  }}
                >
                  Log out
                </span>
              </div>
            )}
          </>
        ) : (
          <div className="navbar--content">
            {!hideLinks && (
              <div className="navbar--links-container">
                <span
                  className="navbar--links"
                  onClick={() => smoothScroll(props.featureCard4Ref)}
                >
                  Cos’è condexo pay
                </span>
                <span
                  className="navbar--links"
                  onClick={() => smoothScroll(props.featureCard1Ref)}
                >
                  Pagamenti
                </span>
              </div>
            )}
            <div className="navbar--buttons">
              <Link to="/login">
                <Button
                  variant="outlined"
                  size="large"
                  style={{
                    width: '80px',
                    borderRadius: '2px',
                    border: 'none',
                    background: '#246BB3',
                  }}
                >
                  <span style={{ color: '#fff' }}>Login</span>
                </Button>
              </Link>
              {/* <Link to="/register">
                <Button
                  style={{
                    width: '160px',
                    marginLeft: '12px'
                  }}
                  color="secondary"
                  rounded
                  size="large"
                >
                  Registrati
                </Button>
              </Link> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: AuthSelectors.selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  setLoggedOut: () => dispatch(AuthActions.setLoggedOut()),
  resetBackToInitialState: () =>
    dispatch(GiftCardActions.resetBackToInitialState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
