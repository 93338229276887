import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import PersonalDetailsForm from './form';
import validationSchema from './schema';
import GiftCardActions, {
  GiftCardSelectors,
} from '../../../redux/reducers/GiftCardRedux';
import './style.scss';
import { isEmpty } from 'lodash';

const PersonalDetails = ({
  supplier,
  activeProduct,
  activeGiftCard,
  appendTopUpGiftCardRequestObj,
  initialAmount,
  activeAmount,
  setActiveAmount,
  setScreen,
  topUpGiftCardRequestObj,
  topUpGiftCardRequest,
  isGuestUser,
  ...restProps
}) => {
  const values = {
    amount:
      activeProduct && activeProduct.faceValue === 0
        ? !isEmpty(topUpGiftCardRequestObj) &&
          topUpGiftCardRequestObj.amount !== 0
          ? topUpGiftCardRequestObj.amount
          : 5
        : parseFloat(initialAmount),
    email: topUpGiftCardRequestObj.email || '',
    desc: topUpGiftCardRequestObj.desc || '',
    supplier: supplier || activeGiftCard.supplier,
    amazonId: topUpGiftCardRequestObj.amazonId || '',
    isGuestUser,
    source: topUpGiftCardRequestObj.path || window.location.pathname,
  };

  const [isAccepted, setIsAccepted] = useState(false);
  const [showTooltip, setTooltipVisibility] = useState(false);

  useEffect(() => {
    if (isAccepted) {
      setTooltipVisibility(false);
    }
  }, [isAccepted]);

  useEffect(() => {
    if (activeGiftCard && activeGiftCard.supplier) {
      supplier = activeGiftCard.supplier;
    }
  }, [activeGiftCard]);

  const handleSubmit = async (values, actions) => {
    const { setSubmitting } = actions;
    setSubmitting(true);
    try {
      // props.setFormData(values)
      if (isAccepted) {
        values.source = window.location.pathname;
        appendTopUpGiftCardRequestObj(values);
        topUpGiftCardRequest(topUpGiftCardRequestObj);
        setTooltipVisibility(false);
      } else {
        setTooltipVisibility(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Formik
      render={(props) => (
        <PersonalDetailsForm
          {...props}
          supplier={supplier}
          activeProduct={activeProduct}
          initialAmount={initialAmount}
          setActiveAmount={setActiveAmount}
          isAccepted={isAccepted}
          setIsAccepted={setIsAccepted}
          showTooltip={showTooltip}
          setTooltipVisibility={setTooltipVisibility}
        />
      )}
      initialValues={values}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    />
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
