import { createMuiTheme } from '@material-ui/core/styles';
import { FormatColorTextOutlined } from '@material-ui/icons';

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: 14,
    fontWeight: 300,
  },
  palette: {
    primary: {
      main: '#1a315b',
      contrastText: 'hsl(0, 100%, 100%)',
      border: '1px solid #1a315b',
    },
    secondary: {
      main: '#a4abb5',
      contrastText: 'hsl(0, 100%, 100%)',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none !important',
        maxHeight: '3.5em',
        fontSize: 14,
        minWidth: 150,
        borderRadius: 0,
        padding: '12px 24px !important',
      },
      MuiButtonOutlined: {
        padding: '12px 24px',
        border: '1px solid #1a315b',
      },
    },
  },
});

export default theme;
