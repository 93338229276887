import React from 'react';
import { mergeAndFormatAmount, stringToCurrency } from '../../utils/currency';
import {
  getImportoParziale,
  getPartialAmount,
  getPartialImport,
  getTotalInclusiveOfCommissionsAndPaytipper,
} from '../../utils/commissions';
const Sidebar = ({
  activeVariant,
  accountNo,
  dataStepOne,
  dataM: { mavCode, ravCode } = {},
  data: { amountToLeftOfDecimal, amountToRightOfDecimal, last4Digits } = {},
}) => {
  const getTypeOfPurchase = () =>
    activeVariant === 'mav-rav'
      ? 'MAV/RAV'
      : activeVariant === 'rata'
      ? 'RATA'
      : 'Bianco generico';
  return (
    <div
      className="pay-your-bill__sidebar"
      style={last4Digits ? { height: '460px' } : {}}
    >
      <div className="pay-your-bill__sidebar__header">Riepilogo dati:</div>
      <div className="pay-your-bill__sidebar__content">
        <div style={{ backgroundColor: '#f6f6f6', padding: 15 }}>
          <div className="pay-your-bill__sidebar__row">
            <span className="pay-your-bill__sidebar__label">Tipologia</span>
            <span className="pay-your-bill__sidebar__value">
              {getTypeOfPurchase()}
            </span>
          </div>
          <div className="pay-your-bill__sidebar__row">
            <span className="pay-your-bill__sidebar__label">Numero C/C</span>
            <span className="pay-your-bill__sidebar__value">
              {dataStepOne}
              {mavCode}
              {ravCode}
            </span>
          </div>
          <div
            className="pay-your-bill__sidebar__row"
            style={{ alignItems: 'end' }}
          >
            <span className="pay-your-bill__sidebar__label">
              Importo iniziale
            </span>
            <span className="pay-your-bill__sidebar__value">
              {mergeAndFormatAmount(
                amountToLeftOfDecimal,
                amountToRightOfDecimal
              )}
            </span>
          </div>
        </div>
        <div style={{ padding: 15 }}>
          <div className="pay-your-bill__sidebar__row">
            <span className="pay-your-bill__sidebar__label">
              Commissioni Paytipper
            </span>
            <span className="pay-your-bill__sidebar__value">{'2,00 €'}</span>
          </div>
        </div>
        <div>
          <div
            className="pay-your-bill__sidebar__row"
            style={{
              backgroundColor: '#f6f6f6',
              alignItems: 'end',
              padding: 15,
            }}
          >
            <span className="pay-your-bill__sidebar__label">
              Importo parziale
            </span>
            <span className="pay-your-bill__sidebar__value">
              {stringToCurrency(
                getPartialAmount(
                  +`${amountToLeftOfDecimal}.${amountToRightOfDecimal}`
                )
              )}
            </span>
          </div>
        </div>
        <div>
          <div className="pay-your-bill__sidebar__row" style={{ padding: 15 }}>
            <span className="pay-your-bill__sidebar__label">
              Commissioni Condexo
            </span>
            <span className="pay-your-bill__sidebar__value">{'0,25 €'}</span>
          </div>

          <div className="pay-your-bill__sidebar__row" style={{ padding: 15 }}>
            <span className="pay-your-bill__sidebar__label">
              Commissioni Stripe
            </span>
            <span className="pay-your-bill__sidebar__value">
              {'1,4% + 0,25 €'}
            </span>
          </div>
          <div
            className="pay-your-bill__sidebar__row sum"
            style={{
              backgroundColor: '#f6f6f6',
              alignItems: 'end',
              padding: 15,
            }}
          >
            <span className="pay-your-bill__sidebar__label sum">
              Importo totale
            </span>
            <span className="pay-your-bill__sidebar__value sum">
              {stringToCurrency(
                getTotalInclusiveOfCommissionsAndPaytipper(
                  +`${amountToLeftOfDecimal}.${amountToRightOfDecimal}`
                )
              )}
            </span>
          </div>
        </div>
        <div></div>
        {/* {last4Digits && last4Digits !== 'new' && last4Digits !== true && (
          <div className="pay-your-bill__sidebar__row">
            <span className="pay-your-bill__sidebar__label">
              Carta di credito
            </span>
            <span className="pay-your-bill__sidebar__value">
              {`XXXX XXXX XXXX ${last4Digits}`}
            </span>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Sidebar;
