import React, { useEffect } from 'react';
import { Logo } from '../Logo';
import { Page, PageContent } from '../../containers/layout';
import { connect } from 'react-redux';
import UIActions from '../../redux/reducers/UIRedux';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import history from '../../utils/history';
import './AccountClosed.scss';

const AccountClosed = (props) => {
  useEffect(() => {
    props.hideNavbar();
  }, []);
  const handleClick = () => {
    history.push('/');
  };
  return (
    <Page className="account-closed__container">
      <div className="account-closed__card">
        <Logo isDark />
        <p>Il tuo account è stato chiuso correttamente.</p>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={handleClick}
        >
          Torna alla Home
        </Button>
      </div>
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => ({
  hideNavbar: () => dispatch(UIActions.hideNavbar()),
});

export default connect(null, mapDispatchToProps)(AccountClosed);
