import React from 'react';
const Footer = ({ currentPath }) => {
  return (
    <footer className="footerSecure">
      {/* {(currentPath === '/login' ||
        currentPath === '/registrazione' ||
        currentPath === '/ricariche') && (
        <> */}
      <div className="footer0">
        Condexo © {1900 + new Date().getYear()} CondexoPay. All rights
        reserved.&nbsp;
      </div>
      <div className="footer1">
        <a
          href={`${window.location.origin}/privacy`}
          rel="noopener noreferrer"
          target="_blank"
          style={{
            color: '#4a90e2',
            textDecoration: 'underline',
          }}
        >
          Privacy policy.
        </a>
      </div>
      <div className="footer2">
        <a
          href="mailto:pay@condexo.it"
          rel="noopener noreferrer"
          target="_blank"
          style={{
            color: '#4a90e2',
            textDecoration: 'underline',
          }}
        >
          Assistenza.
        </a>
      </div>
    </footer>
  );
};

export default Footer;
