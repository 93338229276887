import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { finalPayment } from '../../assets/images/';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  grid: {
    //  minHeight: 'calc(100vh - 20px)',
    height: '100%',
    width: '100%',
  },
  button: {
    marginTop: 20, //da sostituire in futuro con lo space del tema prima del grid
    color: '#fff',
    background: '#fdbd39ff',
    fontWeight: '600',
    borderRadius: 50,
    padding: '6px 20px',
    '&:hover': {
      background: '#fd9d39',
      boxShadow: 'none',
    },
  },
  paper: {
    margin: 'auto',
    maxWidth: '100vh',
    textAlign: 'center',
    borderRadius: 10,
    display: 'flex',
    height: '100%',
  },
  img: {
    margin: 0,
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  typography: {
    color: '#1a315b',
    h5: {
      fontWeight: 500,
    },
    navbar: {
      background: '#fff',
    },
  },
  padding40: {
    padding: 40,
  },
  padding60: {
    padding: 60,
    ['@media (max-width: 700px)']: {
      padding: 20,
    },
  },
}));

const PaymentSuccess = (props) => {
  const classes = useStyles();
  const { isUser } = props;
  const goTo = () => {
    if (isUser) {
      window.location.href = '/dashboard';
    } else {
      window.location.href = '/';
    }
  };
  return (
    <div>
      <Grid container alignItems="center" className={classes.grid}>
        <Paper elevation={4} className={classes.paper}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ height: '100%' }}
          >
            {/* <Grid item xs={12} md={4} lg={4}>
              <img className={classes.img} alt="complex" src={finalPayment} />
            </Grid> */}

            <Grid item xs={12} md={12} lg={12} className={classes.padding60}>
              <Typography
                className={classes.typography}
                gutterBottom
                variant="h5"
              >
                <b>Grazie per aver scelto CondexoPay</b>
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle1"
                className={classes.typography}
              ></Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.typography}
              >
                Riceverai una mail per l’avvenuta conferma della transazione
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.typography}
              ></Typography>
              <Button className={classes.button} onClick={goTo}>
                TORNA ALLA HOME
              </Button>
            </Grid>
          </Grid>
          {/* </Grid> */}
          {/* </Grid> */}
        </Paper>
      </Grid>
    </div>
  );
};

export default PaymentSuccess;
