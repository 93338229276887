import React, { useCallback, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  button: {
    marginTop: 20, //da sostituire in futuro con lo space del tema prima del grid
    color: '#fff',
    background: '#fdbd39ff',
    fontWeight: '600',
    borderRadius: 50,
    padding: '6px 20px',
    '&:hover': {
      background: '#fd9d39',
      boxShadow: 'none',
    },
  },
  grid: {
    // minHeight: 'calc(100vh - 20px)',
  },
  paper: {
    margin: 'auto',
    maxWidth: '100vh',
    textAlign: 'center',
    borderRadius: 10,
    display: 'flex',
    padding: 60,
    width: '43em',
  },
  typography: {
    color: '#1a315b',
    h5: {
      fontWeight: 500,
    },
    navbar: {
      background: '#fff',
    },
  },
}));

const PaymentFeedback = ({ data, goToPayment, isUser }) => {
  const classes = useStyles();

  const goToPaymentUser = useCallback(() => {}, [data]);

  const AnnullaOperazione = () => {
    console.log('isUser', isUser);
    if (isUser) {
      window.location.href = '/dashboard';
    } else {
      console.log('localStorage', window.localStorage.getItem('activeVariant'));
      if (
        window.localStorage.getItem('activeVariant') &&
        window.localStorage.getItem('activeVariant').includes('rata')
      ) {
        window.localStorage.removeItem('activeVariant');
        window.location.href = '/rata';
      } else {
        window.location.href = window.location.pathname;
      }
    }
  };

  return (
    <div className="final guest">
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <Paper elevation={4} className={classes.paper}>
          <Grid container container direction="row" justifyContent="center">
            <Grid item xs container direction="column">
              <Grid item xs>
                <Typography
                  className={classes.typography}
                  gutterBottom
                  variant="h5"
                >
                  <b>Operazione in sospeso!</b>
                </Typography>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  className={classes.typography}
                >
                  La transazione non è stata portata a termine
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  className={classes.typography}
                >
                  ti consigliamo di proseguire con il pagamento
                </Typography>
              </Grid>
              <Grid item xs>
                <Button className={classes.button} onClick={goToPayment}>
                  PROSEGUI CON IL PAGAMENTO
                </Button>
              </Grid>
              <Grid item />
              <Grid item xs style={{ paddingTop: 20 }}>
                <a
                  style={{
                    background: 'none',
                    color: ' grey',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={AnnullaOperazione}
                >
                  Annulla l'operazione
                </a>
              </Grid>
            </Grid>
            <Grid item />
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default PaymentFeedback;
