export const BASE_URL =
  process.env.REACT_APP_MODE === 'dev'
    ? 'http://127.0.0.1:4049/it/'
    : process.env.REACT_APP_MODE === 'production'
    ? 'https://api-secure.condexo.it/it/'
    : 'https://api-stagingpay.condexo.it/it/';

export const STRIPE_KEY =
  process.env.REACT_APP_MODE === 'production'
    ? 'pk_live_HqVakBAKo5gmEENKKy3IdZIl00guHOBbHm'
    : 'pk_test_IZfLmixZf5X90R2kryZV21oa00h72hoBwC';

export const IMAGE_UPLOADS_URL =
  process.env.REACT_APP_DEV === 'true'
    ? `/files/files/uploads/`
    : `/files/uploads/`;

export const HJID = process.env.REACT_APP_HJID;
export const HJSV = 6;
