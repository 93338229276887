import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setBollettinoKey: ['stepCount', 'key', 'value'],
  setRataKey: ['stepCount', 'key', 'value'],
  setMavRavKey: ['stepCount', 'key', 'value'],

  setActiveVariant: ['variant'],

  reserveBillRequest: null,
  reserveBillSuccess: ['response'],
  reserveBillFailed: ['response'],
  getBillTransactionRequest: ['transactionId'],
  getBillTransactionSuccess: ['data'],
  getBillTransactionFailed: ['message'],
  makeBillRequest: ['data'],
  makeBillSuccess: ['response'],
  makeBillFailed: ['response'],
  goToPaymentBillRequest: ['data'],
  goToPaymentBillSuccess: ['data'],
  goToPaymentBillFailed: ['message'],

  clearMessages: null,

  resetState: null,
});

export const PayYourBillTypes = Types;
export default Creators;

/* --------- INITIAL STATE ----------- */
export const INITIAL_STATE = {
  bollettino: {
    stepOne: {
      type: '',
      amountToLeftOfDecimal: '',
      amountToRightOfDecimal: '',
      accountNo: '',
      code: '',
      desc: '',
    },
    stepTwo: {
      name: '',
      surname: '',
      ragione: '',
      email: '',
      address: '',
      city: '',
      district: '',
      postalCode: '',
      secondEmail: '',
    },
    stepThree: {
      cardToken: '',
      last4Digits: '',
    },
  },
  rata: {
    stepOne: {
      mavCode: '',
      ravCode: '',
      amountToLeftOfDecimal: '',
      amountToRightOfDecimal: '',
    },
    stepTwo: {
      name: '',
      surname: '',
      mobileNo: '',
      email: '',
    },
    stepThree: {
      selectedCard: '',
    },
  },
  mavRav: {
    stepOne: {
      mavCode: '',
      ravCode: '',
      amountToLeftOfDecimal: '',
      amountToRightOfDecimal: '',
      mode: '',
    },
    stepTwo: {
      name: '',
      surname: '',
      mobileNo: '',
      email: '',
      secondEmail: '',
    },
    stepThree: {
      selectedCard: '',
    },
  },
  reserveTransactionId: '',
  receiptLink: '',
  activeVariant: '',
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  transactionData: null,
};

/* ---------- Selectors ---------------------------- */
export const PayYourBillSelectors = {
  selectIsLoading: (state) => state.payYourBill.isLoading,
  selectSuccessMessage: (state) => state.payYourBill.successMessage,
  selectErrorMessage: (state) => state.payYourBill.errorMessage,
  selectBollettinoState: (state) => state.payYourBill.bollettino,
  selectRataState: (state) => state.payYourBill.rata,
  selectMavRavState: (state) => state.payYourBill.mavRav,
  selectReserveTransactionId: (state) => state.payYourBill.reserveTransactionId,
  selectTransactionData: (state) => state.payYourBill.transactionData,
  selectStripeUrl: (state) => {
    if (state.payYourBill.url) window.location.href = state.payYourBill.url;
    return null;
  },
  selectActiveVariant: (state) => state.payYourBill.activeVariant,
  selectReceiptLink: (state) => state.payYourBill.receiptLink,
};

/* --------- Reducers ----------------- */
// const checkUndefined = (key) => throw new Error('missing argument ' + key);
export const setBollettinoKey = (state, { stepCount, key, value }) => ({
  ...state,
  bollettino: {
    ...state.bollettino,
    [stepCount]: {
      ...state.bollettino[stepCount],
      [key]: value,
    },
  },
});

export const setRataKey = (state, { stepCount, key, value }) => ({
  ...state,
  rata: {
    ...state.rata,
    [stepCount]: {
      ...state.rata[stepCount],
      [key]: value,
    },
  },
});

export const setMavRavKey = (state, { stepCount, key, value }) => ({
  ...state,
  mavRav: {
    ...state.mavRav,
    [stepCount]: {
      ...state.mavRav[stepCount],
      [key]: value,
    },
  },
});

export const setActiveVariant = (state, action) => ({
  ...state,
  activeVariant: action.variant,
});

export const reserveBillRequest = (state, action) => ({
  ...state,
  isLoading: true,
  errorMessage: '',
});
export const reserveBillSuccess = (state, action) =>
  console.log(action) || {
    ...state,
    isLoading: false,
    reserveTransactionId: action.response.user.reserveTransactionId || '',
    url: action.response.user.url || null,
    errorMessage: '',
  };
export const reserveBillFailed = (state, action) => ({
  ...state,
  isLoading: false,
  errorMessage: action.response.data.errors.message,
});

export const makeBillRequest = (state, action) => ({
  ...state,
  isLoading: true,
  successMessage: '',
  errorMessage: '',
  receiptLink: '',
});
export const makeBillSuccess = (state, action) =>
  console.log(action) || {
    ...state,
    isLoading: false,
    successMessage: action.response.message,
    receiptLink: action.response.data.receipt,
    errorMessage: '',
  };
export const makeBillFailed = (state, action) =>
  console.log(action) || {
    ...state,
    isLoading: false,
    successMessage: '',
    errorMessage: action.response.data.errors.message,
  };

export const goToPaymentBillRequest = (state, { data }) => ({
  ...state,
  isLoading: true,
  successMessage: '',
  errorMessage: '',
  receiptLink: '',
  data: data,
});
export const goToPaymentBillSuccess = (state, { data }) =>
  console.log('goToPaymentBillSuccess', data) || {
    ...state,
    isLoading: false,
    reserveTransactionId: data.user.reserveTransactionId || '',
    url: data.user.url || null,
    errorMessage: '',
  };
export const goToPaymentBillFailed = (state, action) => ({
  ...state,
  isLoading: false,
  errorMessage: action.response.data.errors.message,
});

export const clearMessages = (state, action) => ({
  ...state,
  successMessage: '',
  errorMessage: '',
});

export const getBillTransactionRequest = (state) => {
  console.log('getBillTransactionRequest', state);
  return {
    ...state,
    isLoading: true,
    error: null,
  };
};

export const getBillTransactionSuccess = (state, { data }) => {
  const splitted = (data.amount / 100).toString().split(',');
  return {
    ...state,
    transactionData: data,
    rechargeStatus:
      data && (data.status === 'success' || data.status === 'pending')
        ? 'success'
        : 'failed',
    isLoading: false,
    error: null,
  };
};

export const getBillTransactionFailed = (state, { message }) => ({
  ...state,
  error: message,
  isLoading: false,
});

export const resetState = (state, action) => INITIAL_STATE;

/* -------- Hook reducers to Types -------*/
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_BOLLETTINO_KEY]: setBollettinoKey,
  [Types.SET_RATA_KEY]: setRataKey,
  [Types.SET_MAV_RAV_KEY]: setMavRavKey,
  [Types.SET_ACTIVE_VARIANT]: setActiveVariant,
  [Types.RESERVE_BILL_REQUEST]: reserveBillRequest,
  [Types.RESERVE_BILL_SUCCESS]: reserveBillSuccess,
  [Types.RESERVE_BILL_FAILED]: reserveBillFailed,
  [Types.MAKE_BILL_REQUEST]: makeBillRequest,
  [Types.MAKE_BILL_SUCCESS]: makeBillSuccess,
  [Types.MAKE_BILL_FAILED]: makeBillFailed,
  [Types.GO_TO_PAYMENT_BILL_REQUEST]: goToPaymentBillRequest,
  [Types.GO_TO_PAYMENT_BILL_SUCCESS]: goToPaymentBillSuccess,
  [Types.GO_TO_PAYMENT_BILL_FAILED]: goToPaymentBillFailed,
  [Types.CLEAR_MESSAGES]: clearMessages,
  [Types.RESET_STATE]: resetState,
  [Types.GET_BILL_TRANSACTION_REQUEST]: getBillTransactionRequest,
  [Types.GET_BILL_TRANSACTION_SUCCESS]: getBillTransactionSuccess,
  [Types.GET_BILL_TRANSACTION_FAILED]: getBillTransactionFailed,
});
