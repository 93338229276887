import React, { useEffect, useState } from 'react';
import Commission from '../../../../components/Commission';
import Lottie from 'react-lottie';
import * as successAnimation from '../../../../assets/animations/success.json';
import * as errorAnimation from '../../../../assets/animations/error.json';
import Button from '../../../../components/common/Button';
import history from '../../../../utils/history';
import { withStyles } from '@material-ui/core/styles';
import PaymentFailed from '../../../paymentFailed';
import PaymentSuccess from '../../../paymentSuccess';
import PaymentFeedback from '../../../paymentFeedback';

const LightBlueButton = withStyles({
  root: {
    color: '#fff',
    backgroundColor: '#4a90e2',
    border: '1px solid',
    borderColor: '#4a90e2',
    borderRadius: 0,
    fontWeight: 'normal',

    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#4a90e2',
      boxShadow: 'none',
      color: '#4a90e2',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#fff',
      borderColor: '#4a90e2',
    },
    '&:focus': {},
  },
})(Button);

const LightRedButton = withStyles({
  root: {
    color: '#fff',
    backgroundColor: '#d93879',
    border: '1px solid',
    borderColor: '#d93879',
    borderRadius: 0,
    fontWeight: 'normal',

    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#d93879',
      boxShadow: 'none',
      color: '#d93879',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#fff',
      borderColor: '#d93879',
    },
    '&:focus': {},
  },
})(Button);
const GuestFinalScreen = ({
  baseAmount,
  selectedCard,
  user,
  cards,
  supplier,
  activeEmail,
  setDataForRedirectionAfterLogin,
  phone,
  transactionData,
  goToPayment,
    isUser
}) => {
  const [isFail, setIsFail] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (transactionData) {
      console.log(
        'props.transactionData',
        transactionData,
        transactionData.status
      );
      setIsSuccess(false);
      setIsFail(false);
      setIsPending(false);
      console.log('transactionData', transactionData);
      if (transactionData && transactionData.status === 'fail') {
        setIsFail(true);
      } else if (transactionData && transactionData.status === 'pending') {
        setIsPending(true);
      } else {
        setIsSuccess(true);
      }
    }
  }, [transactionData]);

  const handleClick = () => {
    setDataForRedirectionAfterLogin(activeEmail);
    history.push('/login');
  };
  const handleReturnClick = () => {
    history.push('/ricariche');
  };

  return (
    <div className="final guest">
      {isSuccess && <PaymentSuccess  isUser={isUser} />}
      {isFail && <PaymentFailed />}
      {isPending && (
        <PaymentFeedback data={transactionData} goToPayment={goToPayment} isUser={isUser} />
      )}
      {/* <div className="final-content">
        <h2>L’operazione è andata a buon fine!</h2>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: successAnimation.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          style={{
            margin: '40px 0 40px',
          }}
          height={96}
          width={96}
        />
        <p>
          {supplier || 'WIND TRE'}
          &nbsp;accrediterà la ricarica entro 24h. <br />
          Per info contatta il {phone}
        </p>
        <p>Fai login per visualizzare le ricevute dei tuoi pagamenti</p>
        <LightBlueButton
          variant="contained"
          color="primary"
          fullWidth
          style={{ width: '250px', height: '40px', marginTop: '52px' }}
          onClick={handleClick}
          // onClick={() => history.push('/')}
        >
          {'Accedi'}
        </LightBlueButton>
      </div> */}
      {isSuccess && (
        <Commission
          baseAmount={baseAmount}
          data={transactionData}
          last4Digits={
            selectedCard && selectedCard !== 'new' && user && user._id
              ? cards[
                  cards.findIndex((card) => card.stripeCardId === selectedCard)
                ].cardNumber
              : ''
          }
          typeOfPayment={transactionData.productName}
        />
      )}
    </div>
  );
};

export default GuestFinalScreen;
