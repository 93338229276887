import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import GiftCardActions, {
  GiftCardSelectors,
} from '../../../redux/reducers/GiftCardRedux';
import isEmpty from 'lodash/isEmpty';
import history from '../../../utils/history';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button } from '@material-ui/core';
import { getImageURL } from '../../../utils';
import { stringToCurrency } from '../../../utils/currency';
import './style.scss';

// const BlueButton = withStyles({
//   root: {
//     color: '#1a315b',
//     backgroundColor: '#fff',
//     border: '1px solid',
//     borderColor: '#1a315b',
//     borderRadius: 0,
//     fontWeight: 'normal',

//     '&:hover': {
//       backgroundColor: '#1a315b',
//       borderColor: '#1a315b',
//       boxShadow: 'none',
//       color: '#fff',
//     },
//     '&:active': {
//       boxShadow: 'none',
//       backgroundColor: '#1a315b',
//       borderColor: '#1a315b',
//       color: '#fff',
//     },
//     '&:focus': {},
//   },
// })(Button);

const productArr = [
  'microsoft',
  'phoneall',
  'sony',
  'clouditalia',
  'infinity',
  'dazn',
  'telecom italia',
];

const ProductCard = ({
  data,
  supplier,
  logo,
  setIsRun,
  setActiveProduct,
  setActiveAmount,
  setIsRedirecting,
}) => {
  const isVariable = data.faceValue === 0;
  // if (isVariable) return <></>;
  console.log(data, supplier);
  const redirectToPersonalDetails = () => {
    setIsRun(true);
    console.log(
      'product listing --- ',
      data.faceValue === 0 ? 5 : data.faceValue
    );
    setActiveAmount(data.faceValue === 0 ? 5 : data.faceValue);
    setActiveProduct(data);
    setIsRedirecting(true);
    // history.push(`/gift-card/${data.supplier}`);
  };
  return (
    <div className={`product-card ${isVariable ? 'variable' : ''}`}>
      <div className={`product-card__img__container ${supplier}`}>
        <img src={getImageURL(logo)} alt={supplier} />
      </div>
      <span className="product-card__name">
        {supplier.toLowerCase()} Giftcard
      </span>
      {productArr.includes(supplier.toLowerCase()) && (
        <div
          className="product-card__name"
          style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '16px' }}
        >
          {data.product}
        </div>
      )}
      <span className="product-card__amount">
        {isVariable
          ? 'Scegli tu la cifra da 5,00 € a 500,00 €'
          : `${stringToCurrency(data.faceValue)}`}
      </span>
      <Button
        onClick={redirectToPersonalDetails}
        variant="outlined"
        color="primary"
        // size="small"
        // style={{
        //   padding: '11px 37px',
        //   marginTop: '10px',
        //   borderRadius: 0,
        // }}
      >
        Scegli
      </Button>
    </div>
  );
};

const GiftCardProductListing = ({
  giftCardList,
  activeGiftCard,
  activeProduct,
  setActiveGiftCard,
  setActiveProduct,
  setActiveAmount,
}) => {
  useEffect(() => {
    // giftCardList.
  }, []);
  const [isRun, setIsRun] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    if (isEmpty(activeGiftCard)) {
      //history.goBack();
    }
    console.log('activeGiftCard', activeGiftCard);
  }, [activeGiftCard]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!isRun) {
      setIsRun(true);
      return;
    }

    if (!isEmpty(activeProduct)) {
      console.log(activeProduct);
      history.push(
        `/gift-card/${activeGiftCard.supplier}/${activeProduct.faceValue}`
      );
    }
    setIsRedirecting(false);
  }, [activeProduct, isRedirecting]);

  const renderProductCards = () => {
    return activeGiftCard.products.map((card) => (
      <ProductCard
        data={card}
        supplier={activeGiftCard.supplier}
        logo={activeGiftCard.logo}
        setActiveProduct={setActiveProduct}
        setIsRun={setIsRun}
        setActiveAmount={setActiveAmount}
        setIsRedirecting={setIsRedirecting}
      />
    ));
  };
  if (!isEmpty(activeGiftCard)) {
    return (
      <div
        className={`product-card__container i${
          activeGiftCard.products.length > 4
            ? 4
            : activeGiftCard.products.length
        }`}
      >
        {renderProductCards()}
      </div>
    );
  } else {
    return <span />;
  }
};

const mapStateToProps = (state) => ({
  giftCardList: GiftCardSelectors.selectData(state),
  activeGiftCard: GiftCardSelectors.selectActiveGiftCard(state),
  activeProduct: GiftCardSelectors.selectActiveProduct(state),
  // isAccountClosed: MyProfileSelectors.selectIsAccountClosed(state)
});

const mapDispatchToProps = (dispatch) => ({
  setActiveGiftCard: (card) =>
    dispatch(GiftCardActions.setActiveGiftCard(card)),
  setActiveProduct: (product) =>
    dispatch(GiftCardActions.setActiveProduct(product)),
  setActiveAmount: (amount) =>
    dispatch(GiftCardActions.setActiveAmount(amount)),
  // showNavbar: () => dispatch(UIActions.showNavbar()),
  // commento da rimuovere
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardProductListing);
