// logo
export const logo = require('./logo.svg');
export const logoForNavbar = require('./logo-for-navbar.svg');
export const logoAlt = require('./logoAlt.png');
// register page
export const registerFinalStep = require('./register/final-step.jpg');
//Login page
export const loginSidebar = require('./login/sidebar.webp');
//forgot page
export const forgotPasswordSidebar = require('./forgotPassword/forgotpass.jpg');
//landing page
export const landingHero = require('./landing/hero.png');
export const landingFeature1 = require('./landing/feature-1.jpg');
export const landingFeature1GiftCard = require('./landing/feature-1-giftcard.png');
export const landingFeature1Bollettini = require('./landing/feature-1-bollettini.png');
export const landingFeature1Rate = require('./landing/feature-1-rate.png');
export const landingFeature1Mav = require('./landing/feature-1-mav.png');
export const landingFeature2 = require('./landing/feature-2.png');
export const landingFeature2Map = require('./landing/feature-2-map.svg');
export const landingFeature4 = require('./landing/feature-4.png');
// epay page
export const epayAmazon = require('./epay/amazon.png');
export const epayOperators = require('./epay/operators.png');
export const epayGiftCards = require('./epay/giftCards.png');
export const epayDigi = require('./epay/digi.png');
export const epayFastCard = require('./epay/fast-card.png');
export const epayHo = require('./epay/ho.png');
export const epayIliad = require('./epay/iliad.jpg');
export const epayKena = require('./epay/kena.png');
export const epayLycaMobile = require('./epay/lycamobile.png');
export const epayPosteMobile = require('./epay/poste-mobile.png');
export const epayVisa = require('./epay/visa.png');
export const epayMasterCard = require('./epay/master-card.png');
export const epayPaypal = require('./epay/paypal.png');
export const epayGenericCard = require('./epay/genericCard.png');
export const epayAmericanExpress = require('./epay/american-express.png');
// dashboard page
export const dashboardCard = require('./dashboard/dashboardCard.png');
export const dashboardEuro = require('./dashboard/dashboardEuro.png');

// Bill Payment
export const billPaymentBarCode = require('./bill-payments/barcode.png');

// Final Payment
export const finalPayment = require('./final-payment/finalPayment.webp');
