import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getGiftCardListRequest: ['searchQuery'],
  getGiftCardListSuccess: ['giftCardList', 'activeGiftCard'],
  getGiftCardListFailed: ['errorMessage'],
  setActiveGiftCard: ['card'],
  setActiveProduct: ['product'],
  appendTopUpGiftCardRequestObj: ['data'],
  topUpGiftCardRequest: ['data'],
  topUpGiftCardSuccess: ['data'],
  topUpGiftCardFailed: ['errorMessage'],
  goToPaymentGiftCardRequest: ['data'],
  goToPaymentGiftCardSuccess: ['data'],
  goToPaymentGiftCardFailed: ['errorMessage'],
  resetIsCompleted: null,
  clearMessages: null,
  setActiveAmount: ['amount'],
  resetBackToInitialState: null,
  getGiftTransactionRequest: ['transactionId'],
  getGiftTransactionSuccess: ['data'],
  getGiftTransactionFailed: ['message'],
});

export const GiftCardTypes = Types;
export default Creators;

/* ------- Initial State --------- */
export const INITIAL_STATE = {
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  giftCardList: [],
  isCompleted: false,
  topUpGiftCardRequestObj: {
    userId: '',
    mobile: '',
    type: '',
    eanNo: '',
    amount: 0,
    productName: '',
    supplier: '',
    email: '',
    stripeCustomerId: '',
    paymentSource: '',
    source: '',
  },
  activeGiftCard: {
    /*   _id: 'Amazon',
    supplier: 'Amazon',
    logo: 'amazon.png',
    products: [
      {
        _id: '5f0c10c158da204792b42517',
        product: 'Amazon Pin 10€',
        productType: 'PIN',
        faceValue: 10,
        model: 'B&S',
        eanNo: '4260354156891',
      },
      {
        _id: '5f0c10c258da204792b42518',
        product: 'Amazon Pin 25€',
        productType: 'PIN',
        faceValue: 25,
        model: 'B&S',
        eanNo: '4260354156907',
      },
      {
        _id: '5f0c10c358da204792b42519',
        product: 'Amazon Pin 50€',
        productType: 'PIN',
        faceValue: 50,
        model: 'B&S',
        eanNo: '4260354156914',
      },
      {
        _id: '5f0c10c358da204792b4251a',
        product: 'Amazon Pin 100€',
        productType: 'PIN',
        faceValue: 100,
        model: 'B&S',
        eanNo: '4260354156921',
      },
      {
        _id: '5f0c10c458da204792b4251b',
        product: 'Amazon Ricarica',
        productType: 'Ricarica online',
        faceValue: 0,
        model: 'B&S',
        eanNo: '0085143200707',
      },
    ], */
  },

  activeProduct: {
    /*  _id: '5f0c10c358da204792b4251a',
    product: 'Amazon Pin 100€',
    productType: 'PIN',
    faceValue: 100,
    model: 'B&S',
    eanNo: '4260354156921', */
  },
  activeAmount: 5,
};

/* ------- Selectors --------- */
export const GiftCardSelectors = {
  selectIsLoading: (state) => state.giftCard.isLoading,
  selectSuccessMessage: (state) => state.giftCard.successMessage,
  selectErrorMessage: (state) => state.giftCard.errorMessage,
  selectData: (state) => state.giftCard.giftCardList,
  selectActiveGiftCard: (state) => state.giftCard.activeGiftCard,
  selectActiveProduct: (state) => state.giftCard.activeProduct,
  selectTopUpGiftCardRequestObj: (state) =>
    state.giftCard.topUpGiftCardRequestObj,
  selectIsCompleted: (state) => state.giftCard.isCompleted,
  selectActiveAmount: (state) => state.giftCard.activeAmount,
  selectTransactionData: (state) => state.giftCard.transactionData,
  selectStripeUrl: (state) => {
    if (state.giftCard.url) window.location.href = state.giftCard.url;
    return null;
  },
};

/* -------- Reducers ---------- */
export const getGiftCardListRequest = (state, action) => ({
  ...state,
  isLoading: true,
  successMessage: '',
  errorMessage: '',
  activeGiftCard: {},
  isCompleted: false,
});
export const getGiftCardListSuccess = (state, { giftCardList }) => {
  return {
    ...state,
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    giftCardList,
    activeGiftCard: giftCardList.length === 1 ? giftCardList[0] : {},
  };
};
export const getGiftCardListFailed = (state, { errorMessage }) => ({
  ...state,
  isLoading: false,
  successMessage: '',
  errorMessage,
});

export const setActiveGiftCard = (state, { card }) => ({
  ...state,
  activeGiftCard: card,
});

export const setActiveProduct = (state, { product }) => ({
  ...state,
  activeProduct: product,
});

export const appendTopUpGiftCardRequestObj = (state, { data }) => ({
  ...state,
  topUpGiftCardRequestObj: { ...state.topUpGiftCardRequestObj, ...data },
});

export const topUpGiftCardRequest = (state, data) =>
  console.log('REWY', data) || {
    ...state,
    isLoading: true,
    successMessage: '',
    errorMessage: '',
  };
export const topUpGiftCardSuccess = (state, { data }) => {
  console.log('topUpGiftCardSuccess', data);
  return {
    ...state,
    isLoading: false,
    successMessage: data.message,
    reserveTransactionId: data.user.reserveTransactionId,
    url: data.user.url,
    errorMessage: '',
    isCompleted: true,
  };
};
export const topUpGiftCardFailed = (state, { errorMessage }) => ({
  ...state,
  isLoading: false,
  successMessage: '',
  errorMessage,
  isCompleted: true,
});
export const goToPaymentGiftCardRequest = (state, { data }) =>
  console.log('REWY', data) || {
    ...state,
    isLoading: true,
    successMessage: '',
    errorMessage: '',
    data: data,
  };
export const goToPaymentGiftCardSuccess = (state, { data }) => {
  console.log('topUpGiftCardSuccess', data);
  return {
    ...state,
    isLoading: false,
    successMessage: data.message,
    reserveTransactionId: data.user.reserveTransactionId,
    url: data.user.url,
    errorMessage: '',
    isCompleted: true,
  };
};
export const goToPaymentGiftCardFailed = (state, { errorMessage }) => ({
  ...state,
  isLoading: false,
  successMessage: '',
  errorMessage,
  isCompleted: true,
});
export const resetIsCompleted = (state) => ({
  ...state,
  isCompleted: false,
});

export const setActiveAmount = (state, { amount }) => ({
  ...state,
  activeAmount: amount,
});

export const getGiftTransactionRequest = (state) => ({
  ...state,
  isLoading: true,
  error: null,
});

export const getGiftTransactionSuccess = (state, { data }) => {
  return {
    ...state,
    transactionData: data,
    rechargeStatus:
      data && (data.status === 'success' || data.status === 'pending')
        ? 'success'
        : 'failed',
    isLoading: false,
    error: null,
  };
};

export const getGiftTransactionFailed = (state, { message }) => ({
  ...state,
  error: message,
  isLoading: false,
});

export const clearMessages = (state, action) => ({
  ...state,
  successMessage: '',
  errorMessage: '',
});

export const resetBackToInitialState = (state) => ({
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  giftCardList: [],
  isCompleted: false,
  topUpGiftCardRequestObj: {
    userId: '',
    mobile: '',
    type: '',
    eanNo: '',
    amount: 0,
    productName: '',
    supplier: '',
    email: '',
    stripeCustomerId: '',
    paymentSource: '',
    source: '',
  },
  activeGiftCard: {},
  activeProduct: {},
  activeAmount: 5,
});

/* -------- Hookup Reducers to Types -------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_GIFT_CARD_LIST_REQUEST]: getGiftCardListRequest,
  [Types.GET_GIFT_CARD_LIST_SUCCESS]: getGiftCardListSuccess,
  [Types.GET_GIFT_CARD_LIST_FAILED]: getGiftCardListFailed,
  [Types.SET_ACTIVE_GIFT_CARD]: setActiveGiftCard,
  [Types.SET_ACTIVE_PRODUCT]: setActiveProduct,
  [Types.APPEND_TOP_UP_GIFT_CARD_REQUEST_OBJ]: appendTopUpGiftCardRequestObj,
  [Types.TOP_UP_GIFT_CARD_REQUEST]: topUpGiftCardRequest,
  [Types.TOP_UP_GIFT_CARD_SUCCESS]: topUpGiftCardSuccess,
  [Types.TOP_UP_GIFT_CARD_FAILED]: topUpGiftCardFailed,
  [Types.GO_TO_PAYMENT_GIFT_CARD_REQUEST]: goToPaymentGiftCardRequest,
  [Types.GO_TO_PAYMENT_GIFT_CARD_SUCCESS]: goToPaymentGiftCardSuccess,
  [Types.GO_TO_PAYMENT_GIFT_CARD_FAILED]: goToPaymentGiftCardFailed,
  [Types.RESET_IS_COMPLETED]: resetIsCompleted,
  [Types.SET_ACTIVE_AMOUNT]: setActiveAmount,
  [Types.CLEAR_MESSAGES]: clearMessages,
  [Types.RESET_BACK_TO_INITIAL_STATE]: resetBackToInitialState,
  [Types.GET_GIFT_TRANSACTION_REQUEST]: getGiftTransactionRequest,
  [Types.GET_GIFT_TRANSACTION_SUCCESS]: getGiftTransactionSuccess,
  [Types.GET_GIFT_TRANSACTION_FAILED]: getGiftTransactionFailed,
});
