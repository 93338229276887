import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import { Formik } from 'formik';
import TextInput from '../../../../components/common/form/TextInput';
// import Button from '../../../../components/common/Button';
import Button from '@material-ui/core/Button';
import images from '../../../../assets/icons';
import { withStyles } from '@material-ui/styles';
import Sidebar from '../../Sidebar.jsx';
import './style.scss';
import AcceptAndAgreePolicy from '../../../../components/myPayments/AcceptAndAgreePolicy';
import Grid from '@material-ui/core/Grid';

const BlueButton = withStyles({
  root: {
    color: '#1a315b',
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: '#1a315b',
    borderRadius: 0,
    fontWeight: 'normal',
    padding: '12px 24px',

    '&:hover': {
      backgroundColor: '#1a315b',
      borderColor: '#1a315b',
      boxShadow: 'none',
      color: '#fff',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#1a315b',
      borderColor: '#1a315b',
      color: '#fff',
    },
    '&:focus': {},
  },
})(Button);

const StepTwo = ({
  goStepAhead,
  goStepBack,
  data: { name, surname, ragione, mobileNo, email, ...data },
  handleChange,
  activeVariant,
  amount: { amountToLeftOfDecimal, amountToRightOfDecimal, last4Digits } = {},
  reserveBillRequest,
  reserveTransactionId,
  dataM: { mavCode, ravCode },
}) => {
  const [errors, setErrors] = useState({});
  const [isRun, setIsRun] = useState(false);
  const [flagErrors, setFlagErrors] = useState(true);
  const [isAcceptConditionChecked, setAcceptConditionChecked] = useState(false);
  const [isAgreePrivacyPolicyChecked, setAgreePrivacyPolicyChecked] = useState(
    false
  );
  const [enableTooltipShow, setEnableTooltipShow] = useState(false);

  useEffect(() => {
    if (!isRun) {
      setIsRun(true);
      return;
    }
    if (reserveTransactionId) {
      goStepAhead();
    }
  }, [reserveTransactionId]);

  const handleInputChange = (e) => {
    const {
      target: { name: key, value },
    } = e;
    handleChange('stepTwo', key, value);
    const values = {
      name,
      surname,
      ragione,
      mobileNo,
      email,
      secondEmail: data.secondEmail,
    };
    values[key] = value;
    const { hasErrors, errorsObj } = validateForm(values);
    //if errors then setErrors and exit
    setFlagErrors(hasErrors);
    if (!hasErrors) {
      setErrors({})
    }
  };
  const handleNextScreenRequest = (e) => {
    // validate

    // make api call
    // proceed further to payment scren
    goStepAhead();
  };

  const validateForm = (values) => {
    let hasErrors = false,
      errorsObj = {};
    // console.log('VALUES IN VALIDATE OBJECT', JSON.stringify(values));

    //name - required check, alphabets only
    if (!(values.ragione && values.ragione.trim())) {
      if (!(values.name && values.name.trim())) {
        hasErrors = true;
        errorsObj['name'] = 'Campo obbligatorio';
      } else if (!RegExp(/^[A-Za-z ]+$/).test(values.name)) {
        hasErrors = true;
        errorsObj['name'] = 'Nome Il nome può contenere solo lettere';
      }

      // surname - required check, alphabets only
      if (!(values.surname && values.surname.trim())) {
        hasErrors = true;
        errorsObj['surname'] = 'Campo obbligatorio';
      } else if (!RegExp(/^[A-Za-z ]+$/).test(values.surname)) {
        hasErrors = true;
        errorsObj['surname'] = 'Cognome Il nome può contenere solo lettere';
      }
    }
    if (!(values.surname && values.surname.trim())) {
      if (!(values.ragione && values.ragione.trim())) {
        hasErrors = true;
        errorsObj['surname'] = 'Campo obbligatorio';
      } else if (!RegExp(/^[A-Za-z ]+$/).test(values.ragione)) {
        hasErrors = true;
        errorsObj['ragione'] = 'Cognome Il nome può contenere solo lettere';
      }
    }

    //mobileNo - required check, format check
    if (!(values.mobileNo && values.mobileNo.trim())) {
      hasErrors = true;
      errorsObj['mobileNo'] = 'Campo obbligatorio';
    } else if (!RegExp(/^\d{1,10}$/).test(values.mobileNo)) {
      hasErrors = true;
      errorsObj['mobileNo'] = 'Inserisci un valido numero di telefono';
    }
    //email - required check, format check
    if (!(values.email && values.email.trim())) {
      hasErrors = true;
      errorsObj['email'] = 'Campo obbligatorio';
    } else if (
      !RegExp(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      ).test(values.email)
    ) {
      hasErrors = true;
      errorsObj['email'] = 'Inserire un indirizzo email valido';
    }

    // secondEmail - optional, if Filled then format check
    if (
      values.secondEmail &&
      values.secondEmail.trim() &&
      !RegExp(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      ).test(values.secondEmail)
    ) {
      hasErrors = true;
      errorsObj['secondEmail'] = 'Inserire un indirizzo email valido';
    }

    if (!(isAcceptConditionChecked && isAgreePrivacyPolicyChecked)) {
      hasErrors = true;
      errorsObj['conditionAccepted'] = 'Conditions not accepted';
    }

    return { hasErrors, errorsObj };
  };

  useEffect(() => {
    const { hasErrors, errorsObj } = validateForm({
      name,
      surname,
      ragione,
      mobileNo,
      email,
      secondEmail: data.secondEmail,
    });
    setFlagErrors(hasErrors);
    if (!hasErrors) {
      setErrors({})
    }
  }, [isAcceptConditionChecked, isAgreePrivacyPolicyChecked]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate
    const { hasErrors, errorsObj } = validateForm({
      name,
      surname,
      ragione,
      mobileNo,
      email,
      secondEmail: data.secondEmail,
    });
    setFlagErrors(hasErrors);
    //if errors then setErrors and exit
    if (hasErrors) {
      setErrors(errorsObj);
    } else {
      // else reset errors and proceed
      setErrors({});
      // make the api call and after its response proceed further
      // TODO: MAKE THE REQUIRED API CALL
      reserveBillRequest();
    }
  };

  return (
    <form
      className="mav-rav-and-rata-page__step-two"
      onSubmit={handleSubmit}
      noValidate
    >
      {/* <Formik
        render={(props) => ( */}
      <Card className="mav-rav-and-rata-page__step-two__form">
        <div className="mav-rav-and-rata-page__step-one__icon">
          <img
            src={activeVariant === 'mav-rav' ? images.mavRav : images.rate}
            alt={activeVariant === 'mav-rav' ? 'MAV/RAV' : 'Rata'}
          />
        </div>
        <h3>Eseguito da:</h3>
        <div className="mav-rav-and-rata-page__step-one__row">
          <TextInput
            type="email"
            helperText={errors.name || ''}
            error={!!errors.name}
            // helperText={!!errorMessage ? errorMessage : errors.email}
            // error={!!errorMessage || !!errors.email}
            label="Nome"
            name="name"
            value={name}
            onChange={handleInputChange}
            fullWidth
          />
          <TextInput
            // type="number"
            helperText={errors.surname || ''}
            error={!!errors.surname}
            // helperText={!!errorMessage ? errorMessage : errors.email}
            // error={!!errorMessage || !!errors.email}
            label="Cognome"
            name="surname"
            value={surname}
            onChange={handleInputChange}
            fullWidth
          />
        </div>
        <div className="mav-rav-and-rata-page__step-one__row">
          <TextInput
            type="email"
            label="Ragione Sociale"
            name="ragione"
            helperText={errors.ragione}
            error={!!errors.ragione}
            value={ragione}
            onChange={handleInputChange}
            fullWidth
          />
        </div>
        <div className="mav-rav-and-rata-page__step-one__row">
          <TextInput
            inputProps={{ maxLength: 10 }}
            helperText={errors.mobileNo || ''}
            error={!!errors.mobileNo}
            // helperText={!!errorMessage ? errorMessage : errors.email}
            // error={!!errorMessage || !!errors.email}
            label="Cellulare"
            name="mobileNo"
            value={mobileNo}
            onChange={handleInputChange}
            fullWidth
          />
          <TextInput
            helperText={errors.email || ''}
            error={!!errors.email}
            // helperText={!!errorMessage ? errorMessage : errors.email}
            // error={!!errorMessage || !!errors.email}
            label="Email"
            name="email"
            value={email}
            onChange={handleInputChange}
            fullWidth
          />
        </div>
        {activeVariant === 'rata__mav-rav' && (
          <div className="mav-rav-and-rata-page__rata-container">
            <h4>Contatto amministratore di condominio</h4>
            <p>
              Inserisci la mail del tuo amministratore per comunicare il tuo
              pagamento
            </p>
            <div className="mav-rav-and-rata-page__rata-container__content">
              <TextInput
                label="Email"
                name="secondEmail"
                value={data.secondEmail}
                onChange={handleInputChange}
                helperText={errors.secondEmail || ''}
                error={!!errors.secondEmail}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        )}

        <Grid
          className="bollettino-page__step-two__row"
          style={{ flexWrap: 'wrap' }}
        >
          <AcceptAndAgreePolicy
            isAcceptConditionChecked={isAcceptConditionChecked}
            setAcceptConditionChecked={setAcceptConditionChecked}
            isAgreePrivacyPolicyChecked={isAgreePrivacyPolicyChecked}
            setAgreePrivacyPolicyChecked={setAgreePrivacyPolicyChecked}
            enableTooltipShow={enableTooltipShow}
          />
        </Grid>

        <div
          className="bollettino-page__footer"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button variant="outlined" onClick={goStepBack}>
            Indietro
          </Button>
          <Button
            type="submit"
            color="primary"
            variant={!flagErrors ? 'contained' : 'outlined'}
            onMouseEnter={() => {
              setEnableTooltipShow(true);
              console.log('Mouse pointer eneterd');
            }}
            onMouseLeave={() => {
              setEnableTooltipShow(false);
              console.log('Mouse pointer leave');
            }}
          >
            Procedi
          </Button>
        </div>
      </Card>
      {/*         )}
      /> */}
      <Sidebar
        activeVariant={activeVariant}
        data={{ amountToRightOfDecimal, amountToLeftOfDecimal, last4Digits }}
        dataM={{ mavCode, ravCode }}
      />
    </form>
  );
};

export default StepTwo;
