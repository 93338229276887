import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Tooltip } from '../../common/Tooltip';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 10,
    color: '#224670',
    lineHeight: '20px',
    alignItems: 'center',
  },
  link: {
    fontSize: 10,
    color: '#4a90e2',
  },
}));

export default function AcceptAndAgreePolicy({
  isAcceptConditionChecked,
  setAcceptConditionChecked,
  isAgreePrivacyPolicyChecked,
  setAgreePrivacyPolicyChecked,
  enableTooltipShow,
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Grid container justify="start" alignItems="center">
          <Grid
            item
            xs={1}
            style={{ position: 'relative' }}
            alignItems="center"
          >
            {!(isAcceptConditionChecked && isAgreePrivacyPolicyChecked) &&
              enableTooltipShow && (
                <div
                  style={{
                    position: 'absolute',
                    top: '-50px',
                    left: '-26px',
                  }}
                >
                  <Tooltip>
                    <Typography
                      color="inherit"
                      style={{ fontSize: 10, color: '#9b9b9b' }}
                    >
                      Per procedere è necessario accettare le condizioni
                      generali
                    </Typography>
                  </Tooltip>
                </div>
              )}

            <Checkbox
              name="accept-condition"
              checked={isAcceptConditionChecked}
              color="primary"
              onChange={(event) =>
                setAcceptConditionChecked(event.target.checked)
              }
              inputProps={{
                'aria-label': 'uncontrolled-checkbox',
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <p className={classes.text} style={{ paddingLeft: 15 }}>
              Accetto le{' '}
              <a href="/condizioni" target="_blank" className={classes.link}>
                condizioni
              </a>{' '}
              del servizio e{' '}
              <a href="/privacy" target="_blank" className={classes.link}>
                informativa sulla privacy
              </a>
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="start" alignItems="center">
          <Grid item xs={1}>
            <Checkbox
              checked={isAgreePrivacyPolicyChecked}
              color="primary"
              onChange={(event) =>
                setAgreePrivacyPolicyChecked(event.target.checked)
              }
              name="privacy-policy"
              inputProps={{
                'aria-label': 'uncontrolled-checkbox',
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <p className={classes.text} style={{ paddingLeft: 15 }}>
              Dichiaro di aver preso visione e di accettare:{' '}
              <a
                className={classes.link}
                href="/files/uploads/Foglio_Informativo_Agenzia_Virtuale.pdf"
                target="_blank"
              >
                Foglio Informativo{' '}
              </a>{' '}
              e
              <a
                className={classes.link}
                href="/files/uploads/Privacy_Agenzia_Virtuale.pdf"
                target="_blank"
              >
                {' '}
                Informativa sulla Privacy
              </a>
            </p>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
