import React from 'react';
import DiagonalNavbar from '../../components/common/DiagonalNavbar';
import './style.scss';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions__container">
      <div className="terms-and-conditions__content">
        <b>
          <u>TERMINI E CONDIZIONI UTILIZZO </u>
        </b>
        <br />
        <br />
        <br />

        <p>
          <b>Premessa</b>
          <br />
          Grazie per aver scelto Condexo Pay!
          <br />
          <br />
          Condexo Pay è una piattaforma digitale gestita da Condexo Srl, P.IVA
          15033931005 , iscritta al Registro delle Imprese di Roma al n.
          15033931005, con sede legale in Roma, Piazza di Villa Carpegna, n. 58.
          <br />
          I presenti termini e condizioni di utilizzo (di seguito anche solo
          “T&C”) regolano e disciplinano il rapporto tra l’utente (di seguito
          “Utente”) e Condexo Srl (di seguito anche solo “Condexo”), per la
          fruizione dei servizi erogati per il tramite della piattaforma
          digitale Condexo Pay (di seguito anche solo “Piattaforma”).
          <br />
        </p>
        <br />

        <ol>
          <li>
            <b>Servizi</b>
            <p>
              Mediante l’accesso alla Piattaforma, l’Utente potrà usufruire dei
              seguenti servizi (di seguito anche solo i “Servizi”):
              <br />
              <br />
              <ul type="disc" style={{ marginLeft: '32px' }}>
                <li>
                  Consultazione dell’elenco dei pagamenti effettuabili tramite
                  la Piattaforma;
                </li>
                <li>
                  Gateway di pagamento: ovvero la connessione tra la Piattaforma
                  e lo strumento di pagamento scelto dall’Utente per
                  perfezionare il pagamento;
                </li>
                <li>
                  Interfaccia di pagamento: consente all’Utente, di: i) inserire
                  i dettagli per il pagamento; ii) comunicare, al gestore dello
                  strumento di pagamento, il pagamento da effettuare; iii)
                  ricevere conferma della presa in carico del pagamento da parte
                  del gestore dello strumento di pagamento e del relativo esito;
                  iv) ricevere e trasmettere al Cliente la ricevuta contenente
                  l’esito del pagamento emessa al destinatario del pagamento.
                </li>
                <li>
                  in caso di registrazione alla Piattaforma: i) evidenza dello
                  storico delle transazioni perfezionate per il tramite della
                  Piattaforma; ii) ricevere avvisi di pagamento dai soggetti
                  abilitati dall’Utente (ad es. con riferimento alle scadenze
                  condominiali);
                </li>
                <li>
                  possibilità di accedere ad eventuali servizi accessori che
                  venissero aggiunti sulla Piattaforma.
                </li>
                <li>[altro]</li>
              </ul>
              <br />
              <p>
                Si precisa sin da ora che attraverso la Piattaforma, Condexo
                agisce quale mero fornitore di un servizio di aggregazione e
                collegamento dei servizi di pagamento con le utenze e servizi
                convenzionati, al solo fine di facilitarne la fruizione a
                beneficio dell’Utente, senza quindi mai incassare o disporre
                direttamente delle somme oggetto dei pagamenti, i quali sono
                sempre eseguiti direttamente dai gestori dei servizi di
                pagamento scelti dall’utente e vengono incassati direttamente
                dal destinatario del pagamento.
              </p>
            </p>
            <br />
          </li>
          <li>
            <b>Modalità di accesso alla Piattaforma</b>
            <ol>
              <li data-base-index="2">
                L’accesso alla Piattaforma può avvenire tramite il seguente
                indirizzo web pay.condexo.it. L’accesso alla Piattaforma
                richiede l’utilizzo di un browser internet. Per l’accesso alla
                Piattaforma non è richiesta la registrazione dell’Utente,
                tuttavia ove l’Utente intenda registrarsi, lo stesso potrà
                fruire di un’area riservata ove tenere traccia e consultare
                tutte le operazioni di pagamento effettuate per il tramite della
                Piattaforma.
              </li>
              <li data-base-index="2">
                In ogni caso, per poter utilizzare i Servizi di cui alla
                Piattaforma è necessario che l’Utente abbia almeno 18 anni
                nonché, per poter effettuare pagamenti, che possegga uno
                strumento di pagamento valido tra quelli convenzionati con
                Condexo il cui elenco è disponibile nell’appendice 1 alle
                presenti T&C.
                <br />
                <br />
                Condexo si riserva la facoltà di verificare l’identità
                dell’Utente e la sussistenza dei requisiti mediante la richiesta
                di informazioni o prove documentali.
                <br />
                {/* <br /> */}
              </li>
              <li data-base-index="2">
                Condexo si riserva la facoltà di non erogare i Servizi, dandone
                comunque comunicazione all’Utente, nei casi in cui: (i) l’Utente
                non fornisca le informazioni o i documenti richiesti in fase di
                accesso alla Piattaforma; (ii) Condexo ritenesse non sufficienti
                i documenti prodotti; (iii) nel caso in cui vi sia fondato
                sospetto di utilizzo fraudolento dei sistemi di pagamento. Con
                l’utilizzo dei Servizi, l’Utente acconsente all’esecuzione dei
                controlli sopra descritti e prende comunque atto che Condexo non
                effettua alcuna dichiarazione né verifica che gli strumenti di
                pagamento intestati all'Utente siano validi o funzionanti o che
                il gestore dello strumento di pagamento dell'Utente autorizzi o
                approvi le transazioni richieste.
              </li>
              <li data-base-index="2">
                Inoltre l’Utente è edotto ed approva che Condexo non è coinvolta
                nell'emissione di credito o nella determinazione dell'idoneità
                al credito, né esercita alcun controllo sulla disponibilità o
                accuratezza dei sistemi di pagamento dell’Utente.
                <br />
                <br />
                Condexo precisa che i dati, le informazioni ed i documenti
                forniti dall’Utente in fase di accesso alla Piattaforma saranno
                trattati e conservati solo per il tempo strettamente necessario
                e per le finalità meglio descritte nell’Informativa Privacy
                pubblicata sul sito web pay.condexo.it.
              </li>
            </ol>
          </li>
          <li>
            <b>Modalità di utilizzo della Piattaforma </b>
            <ol>
              <li data-base-index="3">
                Una volta effettuato l’accesso alla Piattaforma, con o senza
                registrazione, l’Utente avrà la possibilità di accedere
                all’elenco dei pagamenti effettuabili tramite la Piattaforma e
                selezionare quello di proprio interesse.
              </li>
              <li data-base-index="3">
                Una volta selezionato il pagamento desiderato, l’Utente per
                procedere con il pagamento dovrà:
                <br />
                <br />
                <ol type="i" className="nested">
                  <li>
                    selezionare il sistema di pagamento desiderato tra quelli
                    convenzionati con Condexo (il cui elenco è disponibile nell’
                    1 alle presenti T&C);
                  </li>
                  <li>
                    inserire i dati per il pagamento richiesti dalla Piattaforma
                    (ad es: numero della carta di credito, username e password,
                    etc.)
                  </li>
                  <li>
                    Selezionare il pagamento richiesto e indicare il relativo
                    ammontare;
                  </li>
                </ol>
              </li>
              <li data-base-index="3">
                Una volta completato l’inserimento dei dati, la Piattaforma
                presenterà un riepilogo del Servizio richiesto ed il relativo
                costo, con evidenza quindi dell’ammontare dei corrispettivi del
                Servizio dovuti e chiederà all’Utente di: a) confermare la
                richiesta del Servizio; b) accettare i termini e condizioni del
                fornitore del servizio/gestore dei servizi di pagamento; c)
                confermare l’importo del pagamento; d) confermare l’accettazione
                del corrispettivo dovuto a Condexo per l’espletamento del
                Servizio.
                <br />
                <br />
                Con la conferma della richiesta l’Utente conferisce a Condexo
                mandato con rappresentanza per:
                <ul className="nested">
                  <li>
                    Trasmettere al gestore del servizio di pagamento la
                    disposizione di pagamento richiesta dal Cliente;
                  </li>
                  <li>
                    Richiedere al gestore del servizio di pagamento una
                    attestazione di conferma dell’esito della disposizione di
                    pagamento richiesta dall’Utente;
                  </li>
                  <li>
                    Richiedere al destinatario del pagamento l’emissione di una
                    ricevuta per il pagamento effettuato.
                  </li>
                </ul>
              </li>
              <li data-base-index="3">
                Ove l’Utente abbia effettuato l’accesso alla Piattaforma previa
                registrazione, avrà la possibilità di accedere ad una sezione
                dedicata dove consultare i propri ordini/acquisti, avendo
                evidenza di tutte le specifiche delle singole transazioni.
              </li>
            </ol>
          </li>
          <li>
            <b>Disciplina dei pagamenti tramite la Piattaforma. </b>
            <ol>
              <li data-base-index="4">
                l’Utente prende atto e accetta che i pagamenti perfezionati per
                il tramite della Piattaforma si intendono effettuati in favore
                del beneficiario indicato dall’Utente al momento della richiesta
                del Servizio, per cui eventuali richieste di rimborso, a
                qualsiasi titolo avanzate dovranno essere avanzate direttamente
                al beneficiario del pagamento.
              </li>
              <li data-base-index="4">
                Condexo non è in nessun caso responsabile per la sicurezza,
                legalità, qualità o qualsiasi altra caratteristica dei servizi
                acquistati/fruiti/ricevuti dall’Utente all’esito del pagamento
                effettuato per il tramite della Piattaforma.
              </li>
              <li data-base-index="4">
                Qualora una transazione debba essere approvata dall’esercente lo
                stesso avrà la possibilità di rifiutare l’operazione di
                pagamento.
              </li>
              <li data-base-index="4">
                A fronte di ogni transazione effettuata tramite la Piattaforma,
                l’Utente sarà tenuto a corrispondere un corrispettivo per il
                Servizio il cui ammontare sarà specificato in fase di pagamento
                e che può variare a seconda del tipo di pagamento e del metodo
                di pagamento selezionato dall’Utente.
              </li>
              <li data-base-index="4">
                Si precisa che Condexo, in nessun caso è parte dei contratti,
                delle norme sulla privacy o di altri termini e condizioni d'uso
                sottoscritti dall'Utente e relativi ai servizi di pagamento da
                lui eventualmente sottoscritti o utilizzati sulla Piattaforma,
                ovvero quelli del beneficiario del pagamento.
              </li>
              <li data-base-index="4">
                Nessuna disposizione dei presenti T&C modifica i contratti, le
                norme sulla privacy o altri termini e condizioni d'uso del
                gestore dei servizi di pagamento e/o del beneficiario del
                pagamento, cui il Cliente è tenuto in ogni caso a conformarsi.
              </li>
              <li data-base-index="4">
                Nel caso di discrepanze tra i presenti T&C e i contratti, le
                norme sulla privacy o altri termini e condizioni d'uso del
                gestore dei servizi di pagamento e/o del beneficiario, i
                presenti T&C disciplineranno il rapporto tra l'Utente e Condexo
                in relazione all’utilizzo della Piattaforma mentre i termini del
                gestore dei servizi di pagamento e/o del beneficiario
                regoleranno il rapporto tra l'utente e l'emittente.
              </li>
            </ol>
          </li>
          <li>
            <b>Responsabilità di Condexo</b>
            <ol>
              <li data-base-index="5">
                Condexo rimane responsabile esclusivamente dei Servizi dalla
                stessa forniti e, pertanto non è responsabile per (i) perdite
                che non siano conseguenza della inosservanza da parte di Condexo
                delle presenti T&C; (ii) per eventuali ritardi del gestore dei
                servizi di pagamento o del beneficiario del pagamento, in
                relazione alle transazioni sottese ai pagamenti effettuati
                tramite la Piattaforma.
              </li>
              <li data-base-index="5">
                Condexo non è responsabile per qualsiasi ritardo o inadempimento
                agli obblighi previsti dalle presenti T&C qualora il ritardo o
                l'inadempimento derivino da caso fortuito o da cause di forza
                maggiore.
              </li>
              <li data-base-index="5">
                Trattandosi di operazioni effettuate sul web, l'accesso
                ininterrotto alla Piattaforma e l'assenza di errori o arresti
                durante le transazioni non possono essere garantiti. Inoltre,
                l’accesso dell’Utente alla Piattaforma potrebbe essere
                temporaneamente sospeso o limitato per consentire
                l'effettuazione di lavori di riparazione, manutenzione, o
                l'introduzione di nuove attività o Servizi sulla Piattaforma.
                Condexo si impegna a limitare al massimo la frequenza e la
                durata di tali interventi.
              </li>
            </ol>
          </li>
          <li>
            <b>Modifiche</b>
            <br />
            Condexo si riserva il diritto di modificare ed integrare i Servizi,
            le funzionalità della Piattaforma, le presenti T&C in qualsiasi
            momento per offrire nuovi prodotti o servizi o per adeguarsi alle
            disposizioni di legge e regolamentari. L’utilizzo della Piattaforma
            da parte dell’Utente sarà quindi disciplinata dalle T&C vigenti al
            momento della singola operazione. Qualora una qualsiasi previsione
            delle presenti T&C fosse ritenuta invalida, nulla o per qualunque
            motivo inapplicabile, tale condizione non pregiudicherà comunque la
            validità e l'efficacia delle altre previsioni.
          </li>
          <li>
            <b>Comunicazioni e assistenza </b>
            L’Utente può contattare Condexo al fine di richiedere assistenza o
            per esporre un eventuale reclamo utilizzando i seguenti canali:
            <ul className="nested">
              <li>via e-mail: scrivendo all’indirizzo pay@condexo.it</li>
              <li>
                via posta ordinaria: scrivendo a Condexo S.r.l. Piazza di Villa
                Carpegna, n, 58, 00165 Roma
              </li>
            </ul>
          </li>
          <li>
            <b>Riservatezza e Proprietà intellettuale </b>
            <ol>
              <li data-base-index="8">
                L’Utente e Condexo si impegnano reciprocamente a mantenere la
                più assoluta riservatezza su dati, notizie ed informazioni
                comunque ricevute nell'adempimento degli obblighi derivanti
                dalle presenti T&C.
              </li>
              <li data-base-index="8">
                L’Utente riconosce espressamente che tutti i diritti di
                proprietà intellettuale, tutelabili sulla base delle norme
                relative al diritto d'autore (Titolo IX del Libro V del c.c.,
                articoli 2575-2594 c.c., nonché L. n. 633/1941, come modificata
                dal D. Lgs. n. 169/1999) o di altre disposizioni, fra cui, a
                puro titolo esemplificativo e non esaustivo, quelle a tutela del
                know-how, codici sorgenti, software, hardware, progetti,
                applicativi, brevetti, segreti industriali, formule, algoritmi,
                modelli, banche dati e simili, segni distintivi di Condexo o
                della Piattaforma o di parte di essi (logo, icone, ecc.), nonché
                in generale tutti i diritti relativi ai Servizi, alla
                Piattaforma, ai dati e agli altri materiali provenienti da
                Condexo, restano di titolarità esclusiva di Condexo (o dei suoi
                aventi causa), restando in ogni caso inteso che l’Utente non è
                autorizzato a copiare, modificare, vendere, cedere,
                sublicenziare, conferire, trasferire a terzi o creare lavori
                derivati da un qualsiasi diritto di Condexo, né consentire che
                terzi lo facciano suo tramite o i suoi strumenti informatici,
                anche a sua insaputa.
              </li>
            </ol>
          </li>
          <li>
            <b>Trattamento dei dati </b>
            <p>
              Condexo è titolare del trattamento dei dati personali dell’Utente
              e li gestisce e tutela in conformità con il Regolamento (UE)
              2016/679 (“GDPR”) e alle altre leggi applicabili in materia di
              protezione dei dati. Per maggiori dettagli l’Utente può prendere
              visione della Informativa Privacy presente sul sito web
              pay.condexo.it.
            </p>
          </li>
          <li>
            <b>Legge applicabile e Foro competente</b>
            <ol>
              <li data-base-index="10">
                Il presente Contratto è regolato dalla Legge Italiana.
              </li>
              <li data-base-index="10">
                Per qualsiasi controversia che dovesse insorgere relativamente
                alla validità, interpretazione, esecuzione e risoluzione delle
                presenti T&C sarà competente in via esclusiva il Foro di
                residenza o domicilio dell’Utente.
              </li>
            </ol>
          </li>
        </ol>
        <br />
        <br />
        <b>
          <u>APPENDICE </u>
        </b>
        <ol>
          <li>
            <b>Metodi di pagamento </b>
            <br />
            Il pagamento è consentito mediante l’utilizzo di Stripe con carte di
            credito, di debito e prepagate con circuiti Visa, Mastercard,
            Maestro e American Express.
          </li>
          <li>
            <b>Condizioni economiche </b>
            <br />
            La commissione CondexoPay applicata per ciascuna tipologia di
            Servizio è di Euro 0,25 per singola transazione.
          </li>
          <li>
            <b>Commissioni transazioni</b>
            <div role="region" ariaLabelledby="Caption01" tabIndex={0}>
              <table className="terms-and-conditions__table">
                <thead>
                  <td>Carte di credito e di debito</td>
                  <td>Pagamenti internazionali</td>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Carte di credito e di debito">
                      1,4% + 0,25€
                    </td>
                    <td data-label="Pagamenti internazionali">2,9% + 0,25 €</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li>
            <b>Costi per le operazioni di pagamento</b>
          </li>
          <table className="terms-and-conditions__table">
            <thead>
              <td>Bollettini</td>
              <td>MAV/RAV</td>
            </thead>
            <tbody>
              <tr>
                <td data-label="Bollettini">2 €</td>
                <td data-label="MAV/RAV">2 €</td>
              </tr>
            </tbody>
          </table>
        </ol>
      </div>
    </div>
  );
};

export default TermsAndConditions;
