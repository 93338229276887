import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PersonalDetails from './personalDetails';
import Payment from './payment';
import GiftCardActions, {
  GiftCardSelectors,
  topUpGiftCardRequest,
} from '../../redux/reducers/GiftCardRedux';
import MyProfileActions from '../../redux/reducers/MyProfileRedux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import UIActions from '../../redux/reducers/UIRedux';
import EpayActions, {
  EpaySelectors,
  getTransactionRequest,
} from '../../redux/reducers/EpayRedux';
import { AuthSelectors } from '../../redux/reducers/AuthRedux';

import Sidebar from './sidebar';
import { Page, PageContent } from '../layout';
import { stringToCurrency } from '../../utils/currency';
import { getTotalInclusiveOfCommissions } from '../../utils/commissions';

import history from '../../utils/history';
import FinalPageGiftCardPurchase from './finalPage';
import isEmpty from 'lodash/isEmpty';
import { Loader } from '../../components/Loader';

import './style.scss';
import { MyProfileSelectors } from '../../redux/reducers/MyProfileRedux';
import { ForgotPasswordSelectors } from '../../redux/reducers/ForgotPasswordRedux';
import FlashMessage from '../../components/common/FlashMessage';
import PayYourBillActions from '../../redux/reducers/PayYourBillRedux';
const GiftCardPurchase = (props) => {
  let {
    activeGiftCard,
    setActiveGiftCard,
    activeProduct,
    setActiveProduct,
    showNavbar,
    appendTopUpGiftCardRequestObj,
    topUpGiftCardRequestObj,
    match,
    topUpGiftCardRequest,
    isCompleted,
    successMessage,
    resetIsCompleted,
    isLoadingEP,
    isLoadingGC,
    isLoadingAC,
    activeAmount,
    setActiveAmount,
    cards,
    resetBackToInitialState,
    isNewUser,
    user,
    setDataForRedirectionAfterLogin,
    getGiftTransactionDataById,
    giftCardList,
    errorMessage,
    clearMessages,
  } = props;
  const { logo, supplier } = !isEmpty(activeGiftCard) ? activeGiftCard : {};

  const [success, setSuccess] = useState(null);
  const [paymentState, setPaymentState] = useState(null);

  const [transactionId, setTransactionId] = useState(null);
  useEffect(() => {
    showNavbar();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (props.match.params && props.match.params) {
      props.getGiftCardListRequest(props.match.params.id);
    }
  }, [props.match]);

  useEffect(() => {
    if (giftCardList && giftCardList.length) {
      setActiveGiftCard(giftCardList[0]);
      const product = giftCardList[0].products.filter(
        (o) => o.faceValue === activeAmount
      )[0];
      setActiveProduct(product);
    }
  }, [giftCardList]);

  const goBack = () => {
    console.log('screen', screen, activeGiftCard, props.match);
    if (props.match && props.match.params && props.match.params.amount) {
      history.push(`/gift-card/${activeGiftCard.supplier}`);
    } else {
      if (props.match && props.match.params && props.match.params.id) {
        history.push(`/ricariche`);
      } else {
        if (screen === 1) {
          history.goBack();
        } else {
          setScreen(screen - 1);
        }
      }
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const paramsSuccess = urlSearchParams.get('success');
    const paramsTransactionId = urlSearchParams.get('t');
    setSuccess(paramsSuccess === null ? paramsSuccess : Number(paramsSuccess));
    setPaymentState(
      paramsSuccess === null ? paramsSuccess : Number(paramsSuccess)
    );
    setTransactionId(paramsTransactionId);
    if (isEmpty(activeGiftCard) && paramsSuccess !== null) {
      setScreen(3);
    }
  }, [activeGiftCard]);

  /* const [activeAmount, setActiveAmount] = useState(
    topUpGiftCardRequestObj.amount
      ? topUpGiftCardRequestObj.amount
      : parseInt(match.params.amount)
      ? parseFloat(match.params.amount)
      : 5
  ); */
  useEffect(() => {
    if (transactionId) {
      getGiftTransactionDataById(transactionId);
    }
  }, [transactionId]);

  useEffect(() => {
    /* if (
      !isEmpty(topUpGiftCardRequestObj) &&
      topUpGiftCardRequestObj.amount !== 0
    ) {
      setActiveAmount(topUpGiftCardRequestObj.amount);
    } else if (match.params.amount) {
      setActiveAmount(parseFloat(match.params.amount));
    } else {
      setActiveAmount(5);
    } */
    if (activeProduct.faceValue === 0) {
      if (
        !isEmpty(topUpGiftCardRequestObj) &&
        topUpGiftCardRequestObj.amount !== 0
      ) {
        setActiveAmount(topUpGiftCardRequestObj.amount);
      } else {
        setActiveAmount(5);
      }
    } else if (match.params.amount) {
      setActiveAmount(parseFloat(match.params.amount));
    }
  }, [topUpGiftCardRequestObj]);

  const [screen, setScreen] = useState(1);
  const [alert, setAlert] = useState({ show: false, variant: '', error: '' });
  const [isRun, setIsRun] = useState(false);
  const [selectedCard, setCard] = useState('');
  const setAndHideAlert = (message, hasErrors) => {
    setAlert({
      ...alert,
      show: true,
      variant: hasErrors ? 'danger' : 'success',
      error: message,
    });
    hideAlert();
  };
  const hideAlert = () => {
    setTimeout(() => {
      setAlert({ ...alert, show: false });
    }, 5000);
  };
  // if its present in redux
  // if not then check params
  // if its 0 then hide it
  useEffect(() => {
    if (topUpGiftCardRequestObj.amount) {
      // setActiveAmount(topUpGiftCardRequestObj.amount);
      // } else if (!parseInt(match.params.amount)) {
      // setActiveAmount(parseFloat(match.params.amount));
      // } else {
      // setActiveAmount(parseFloat(match.params.amount));
    }
  }, [match]);

  useEffect(() => {
    console.log('errorMessage', errorMessage);
    if (!isRun) {
      setIsRun(true);
      return;
    }
    if (alert.show === false) {
      if (errorMessage) {
        setAndHideAlert(errorMessage, true);
      }
    }
  }, [errorMessage, isRun, setAndHideAlert]);

  const handleGoToPayment = () => {
    console.log('props', props);
    props.goToPayment(props.transactionData);
  };
  return (
    <Page>
      <PageContent className="gift-card-purchase">
        <div className="gift-card-purchase__header">
          {(isLoadingEP || isLoadingGC || isLoadingAC) && (
            <Loader belowNavbar />
          )}
          {!(screen > 2) && (
            <button onClick={goBack}>
              {/* <span>&larr;</span> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-arrow-left"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#0357d3"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="5" y1="12" x2="11" y2="18" />
                <line x1="5" y1="12" x2="11" y2="6" />
              </svg>

              <span>Indietro</span>
            </button>
          )}
          <h1>{screen > 2 ? 'Ricarica Online' : 'Acquista la tua giftcard'}</h1>
        </div>
        <div className="gift-card-purchase__content">
          {screen > 2 ? (
            <FinalPageGiftCardPurchase
              isSuccess={successMessage}
              paymentState={paymentState}
              setScreen={setScreen}
              user={user}
              resetIsCompleted={resetIsCompleted}
              supplier={activeGiftCard.supplier}
              amount={stringToCurrency(
                getTotalInclusiveOfCommissions(activeAmount)
              )}
              resetBackToInitialState={resetBackToInitialState}
              // user={user}
              cards={cards}
              setDataForRedirectionAfterLogin={setDataForRedirectionAfterLogin}
              email={topUpGiftCardRequestObj.email}
              transactionId={transactionId}
              success={success}
              transactionData={props.transactionData}
              selectedCard={selectedCard}
              goToPayment={handleGoToPayment}
            />
          ) : (
            <>
              <div
                className={`gift-card-purchase__content__main ${
                  screen === 2 ? 'payment-main' : ''
                }`}
              >
                {/* <FinalPageGiftCardPurchase isSuccess={true} /> */}

                {screen === 1 ? (
                  <PersonalDetails
                    supplier={supplier}
                    activeGiftCard={activeGiftCard}
                    activeProduct={activeProduct}
                    appendTopUpGiftCardRequestObj={
                      appendTopUpGiftCardRequestObj
                    }
                    initialAmount={match.params.amount}
                    activeAmount={activeAmount}
                    setActiveAmount={setActiveAmount}
                    setScreen={setScreen}
                    topUpGiftCardRequest={topUpGiftCardRequest}
                    topUpGiftCardRequestObj={topUpGiftCardRequestObj}
                    isGuestUser={!(user && user._id)}
                  />
                ) : (
                  <Payment
                    topUpGiftCardRequest={topUpGiftCardRequest}
                    setScreen={setScreen}
                    isCompleted={isCompleted}
                    selectedCard={selectedCard}
                    setCard={setCard}
                  />
                )}
              </div>
              <Sidebar
                className="gift-card-purchase__content__sidebar"
                logo={logo}
                supplier={supplier}
                isVariable={activeProduct && activeProduct.faceValue === 0}
                amount={activeAmount}
                last4Digits={
                  selectedCard && selectedCard !== 'new' && user && user._id
                    ? cards[
                        cards.findIndex(
                          (card) => card.stripeCardId === selectedCard
                        )
                      ].cardNumber
                    : ''
                }
                selectedCard={selectedCard}
              />
            </>
          )}
        </div>
      </PageContent>
      {alert.show && (
        <FlashMessage
          message={alert.error}
          hideFlashMessage={() => {
            clearMessages();
            setAlert({ show: false, variant: '', error: '' });
          }}
          variant={alert.variant}
        />
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  activeProduct: GiftCardSelectors.selectActiveProduct(state),
  activeGiftCard: GiftCardSelectors.selectActiveGiftCard(state),
  topUpGiftCardRequestObj: GiftCardSelectors.selectTopUpGiftCardRequestObj(
    state
  ),
  isCompleted: GiftCardSelectors.selectIsCompleted(state),
  successMessage: GiftCardSelectors.selectSuccessMessage(state),
  errorMessage: GiftCardSelectors.selectErrorMessage(state),
  isLoadingGC: GiftCardSelectors.selectIsLoading(state),
  isLoadingEP: EpaySelectors.selectIsLoading(state),
  isLoadingAC: MyProfileSelectors.selectIsLoading(state),
  activeAmount: GiftCardSelectors.selectActiveAmount(state),
  giftCardList: GiftCardSelectors.selectData(state),
  cards: EpaySelectors.selectCards(state),
  user: AuthSelectors.selectCurrentUser(state),
  url: GiftCardSelectors.selectStripeUrl(state),
  transactionData: GiftCardSelectors.selectTransactionData(state),
  // appendTopUpGiftCardRequestObj: GiftCardSelectors
});
const mapDispatchToProps = (dispatch) => ({
  showNavbar: () => dispatch(UIActions.showNavbar()),
  appendTopUpGiftCardRequestObj: (data) =>
    dispatch(GiftCardActions.appendTopUpGiftCardRequestObj(data)),
  topUpGiftCardRequest: (data) =>
    dispatch(GiftCardActions.topUpGiftCardRequest(data)),
  resetIsCompleted: () => dispatch(GiftCardActions.resetIsCompleted()),
  clearMessages: () => dispatch(GiftCardActions.clearMessages()),
  setActiveAmount: (amount) =>
    dispatch(GiftCardActions.setActiveAmount(amount)),
  resetBackToInitialState: () =>
    dispatch(GiftCardActions.resetBackToInitialState()),
  setDataForRedirectionAfterLogin: (email) =>
    dispatch(MyProfileActions.setDataForRedirectionAfterLogin(email)),
  getGiftTransactionDataById: (transactionId) =>
    dispatch(GiftCardActions.getGiftTransactionRequest(transactionId)),
  getGiftCardListRequest: (searchQuery) =>
    dispatch(GiftCardActions.getGiftCardListRequest(searchQuery)),
  setActiveGiftCard: (card) =>
    dispatch(GiftCardActions.setActiveGiftCard(card)),
  setActiveProduct: (product) =>
    dispatch(GiftCardActions.setActiveProduct(product)),
  goToPayment: (transactionData) =>
    dispatch(GiftCardActions.goToPaymentGiftCardRequest(transactionData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardPurchase);
