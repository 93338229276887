import React from 'react';
import './MyPaymentCard.scss';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { VerticalAlignCenter } from '@material-ui/icons';
import { MuiPickersContext } from '@material-ui/pickers';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  IconButton,
} from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    padding: 20,
    verticalAlign: 'center',
    fontSize: 16,
  },
  avatar: {
    objectFit: 'contain',
    borderRadius: 0,
  },
}));

const MyPaymentCard = ({ title, icon, onClick }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} onClick={onClick} sx={{ maxWidth: 300 }}>
      <CardHeader
        avatar={
          <Avatar
            src={icon}
            aria-label="payment-icon"
            className={classes.avatar}
          />
        }
        action={
          <IconButton aria-label="settings">
            <VisibilityIcon />
          </IconButton>
        }
        title={title}
      />
      {/* <Grid container alignItems="center">
          <Grid item xs={4} sm={3}>
            <img src={icon} alt="payment-icon"  />
          </Grid>
          <Grid item sm={6} xs={4}>
            <Typography variant="subtitle1">{title}</Typography>
          </Grid>
          <Grid item xs={4} sm={2} >
            <Button variant="contained" color="primary" onClick={onClick}>
              Vedi
            </Button>
          </Grid>
        </Grid> */}
    </Card>
  );
};

// const MyPaymentCard = ({ title, icon, onClick }) => {
//   return (
//     <div className="my-payment-card" onClick={onClick}>
//       <div className="my-payment-card--icon__container">
//         <img src={icon} alt="payment-icon" className="my-payment-card--icon" />
//       </div>
//       <h5>{title}</h5>
//       <Button variant="outlined" onClick={onClick}>
//         Vedi
//       </Button>
//     </div>
//   );
// };

export default MyPaymentCard;
